import React from 'react';
import { ArrowRight } from '../../constants/icons';
import { Link } from 'react-router-dom';
export const PageSection = ({ className, id, children }) => {
  return (
    <section id={id} className={`py-10 ${className}`}>
      {children}
    </section>
  );
};

export const SectionContainer = ({ className, children }) => (
  <div className={`container px-5 flex justify-between py-4 items-center ${className}`}>
    {children}
  </div>
);

export const SectionChild = ({ className, imageSrc, isImageFirst = false, linkTo, title, caption, children }) => {
  // const imgOrder = isImageFirst ? 'order-none' : '1';
  return (
    <div className={`w-full flex md:flex-row flex-col items-center ${className}`}>
      <div className={`md:w-1/2 w-full md:px-8 py-4 flex flex-col ${!isImageFirst ? 'md:order-first' : 'md:order-last'} order-last order-first`}>
        <h2 className='font-heebo text-3xl text-dark-soft py-8'>{title}</h2>
        <p>{caption}</p>
        {linkTo && <Link to={linkTo} className="btn self-end my-4">Read More <ArrowRight/></Link>}
      </div>
      {imageSrc && <div className={`md:w-1/2 w-full md:px-8 py-4 ${isImageFirst ? 'md:order-first' : 'md:order-last'} order-first`}>
        <img src={imageSrc} alt="section image" className='w-full md:max-w-[460px] mx-auto'/>
      </div> }
    </div>
  );
};
