import React, { useEffect, useState } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';

import './App.css';
import HomePage from './pages/home/HomePage';
import RegisterPage from './pages/register/RegisterPage';
import LoginPage from './pages/login/LoginPage';
import ProfilePage from './pages/admin/screens/settings/ProfilePage';
import AdminLayout from './pages/admin/AdminLayout';
import Admin from './pages/admin/screens/Admin';
import Products from './pages/Products';
import BrandDetailPage from './pages/brandDetail/BrandDetailPage';
import ScrollToTop from './utils/ScrollToTop';
import Translations from './pages/admin/screens/Translations';
import WebSiteSettingsPage from './pages/admin/screens/settings/WebSiteSettingsPage';
import ManageBrands from './pages/admin/screens/brands/ManageBrands';
import NewBrand from './pages/admin/screens/brands/NewBrand';
import EditBrand from './pages/admin/screens/brands/EditBrand';
import NewProduct from './pages/admin/screens/products/NewProduct';
import ManageProducts from './pages/admin/screens/products/ManageProducts';
import EditProduct from './pages/admin/screens/products/EditProduct';
import AboutUsPage from './pages/aboutUS/AboutUsPage';
import Contact from './pages/Contact';
import { locales } from './components/PageData';
import { useTranslation } from 'react-i18next';
import Gallery from './pages/admin/screens/Gallery';
import BrandLayout from './pages/brandDetail/BrandLayout';
import Personalize from './pages/Personalize';
import { images } from './constants';

function App() {
  const location = useLocation();
  const { i18n, t } = useTranslation();
  const [isAdult, setIsAdult] = useState(false);
  const [isMinor, setIsMinor] = useState(null);

  const isBot = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    const bots = ['googlebot', 'bingbot', 'yandexbot', 'baiduspider', 'msnbot', 'duckduckbot', 'teoma', 'slurp', 'bot', 'bingbot', 'applebot', 'sogouspider', 'facebookexternalhit', 'exabot', 'swiftbot', 'slurpbot', 'ccbot', 'googleother', 'googleinspectiontool'];

    return bots.some(bot => userAgent.includes(bot));
  };

  useEffect(() => {
    if(isBot()) {
      setIsAdult(true);
    }
    const pages = ['admin', 'login', 'register'];
    pages.some(page => location.pathname.split('/').includes(page)) && setIsAdult(true);

    // location.pathname.split('/').includes('admin') && setIsAdult(true);
    const loc = location.pathname.split('/').find((item) => Object.keys(locales).includes(item));
    loc && i18n.changeLanguage(loc);
  }, [i18n, location.pathname]);

  return (
    <div className="App font-montserrat">
      {isAdult &&
        <ScrollToTop>
          <Routes>
            <Route index path="/:lang?/" element={<HomePage/>}/>
            <Route path="/:lang?/products" element={<Products/>}/>
            <Route path="/:lang?/personalize" element={<Personalize/>}/>
            <Route path="/:lang?/aboutUs" element={<AboutUsPage/>}/>
            <Route path="/:lang?/contact" element={<Contact/>}/>
            <Route path="/:lang?/register" element={<RegisterPage/>}/>
            <Route path="/:lang?/login" element={<LoginPage/>}/>
            <Route path="/:lang?/brand" element={<BrandLayout/>}>
              <Route path=":slug" element={<BrandDetailPage/>}/>
            </Route>

            <Route path="/:lang?/admin" element={<AdminLayout/>}>
              <Route index element={<Admin/>}/>
              {/* <Route path="about" element={<AboutUs />} /> */}
              <Route path="translations" element={<Translations/>}/>
              <Route path="brands/new" element={<NewBrand/>}/>
              <Route path="brands/manage" element={<ManageBrands/>}/>
              <Route path="brands/manage/edit/:slug" element={<EditBrand/>}/>
              <Route path="products/new" element={<NewProduct/>}/>
              <Route path="products/manage" element={<ManageProducts/>}/>
              <Route path="products/manage/edit/:slug" element={<EditProduct/>}/>
              {/* <Route path="clients/new" element={<NewClient />} /> */}
              {/* <Route path="clients/manage" element={<ManageClients />} /> */}
              {/* <Route path="clients/manage/edit/:slug" element={<EditClient />} /> */}
              {/* <Route path="multiClassReview/new" element={<NewReview />} /> */}
              {/* <Route path="multiClassReview/manage" element={<ManageReview />} /> */}
              {/* <Route path="multiClassReview/manage/edit/:slug" element={<EditReview />} /> */}
              <Route path="gallery" element={<Gallery/>}/>
              <Route path="contacts" element={<WebSiteSettingsPage/>}/>
              <Route path="usersettings" element={<ProfilePage/>}/>
            </Route>
          </Routes>
        </ScrollToTop>
      }
      {!isAdult && <div className='absolute top-0 left-0 w-full min-h-full flex justify-center items-center bg-[#616060] bg-cover bg-center bg-no-repeat' style={{ backgroundImage: `url(${images.AdultBg})` }}>
        <div className="container flex flex-col items-center gap-20 text-center">

          <img alt="logo" className="max-w-[200px]" src={images.ColoredLogo}/>
          <h1 className="text-white md:text-7xl text-4xl">{isMinor ? t('SORRY, THE SITE IS AVAILABLE ONLY TO PERSONS AGED 18 AND OVER.') : t('PLEASE CONFIRM THAT YOU ARE 18 YEARS OLD')}</h1>
          {isMinor && <p className="lead text-white">{t('SMOKING IS HARMFUL TO HEALTH')}</p>}
          {!isMinor && <div className="flex md:gap-20 gap-8 justify-center">
            <button
              className="m-0 lg:flex md:flex gap-x-1 items-center border-2 border-[#D9D9D9] md:px-12 md:py-4 px-6 py-2 text-white text-xl font-MontserratArmSemiBold hover:bg-primary hover:text-white transition-all duration-300"
              onClick={() => setIsAdult(true)}>{t('CONFIRM')}</button>
            <button
              className="m-0 lg:flex md:flex gap-x-1 items-center border-2 border-[#D9D9D9] md:px-12 md:py-4 px-6 py-2 text-black text-xl font-MontserratArmSemiBold transition-all duration-300 bg-[#D9D9D9]"
              onClick={() => setIsMinor(true)}>{t('DECLINE')}</button>
          </div>}
        </div>

      </div>
      }
      <Toaster />
    </div>
  );
}

export default App;
