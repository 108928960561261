import React, { useEffect, useState } from 'react';
import { images, stables } from '../../../../constants';
import Pagination from '../../../../components/Pagination';
import { toast } from 'react-hot-toast';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { deleteProduct, getAllProducts } from '../../../../services/index/products';
import ErrorMessage from '../../../../components/ErrorMessage';
import { useTranslation } from 'react-i18next';

const ManageProducts = () => {
  const userState = useSelector((state) => state.user);
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [isFetching, setIsFetching] = useState(true);
  const [isError, setIsError] = useState(false);
  const [productsData, setProductsData] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    fetchProducts('', currentPage);
  }, [currentPage]);

  const fetchProducts = async (searchKeyword, currentPage) => {
    try {
      const {
        data,
        isLoading,
        isFetching,
        isError
      } = await getAllProducts({ page: currentPage, limit: 100 });
      setProductsData({
        data: data.result,
        pageData: data.pageData
      });
      setIsLoading(isLoading);
      setIsFetching(isFetching);
      setIsError(isError);
    } catch (error) {
      toast.error(error.message);
      console.error(error);
    }
  };

  const handleDeleteProduct = async ({ slug, token }) => {
    if(window.confirm('Do you want to delete this Product?')) {
      try {
        await deleteProduct({ slug, token });
        setIsLoading(true);
        return fetchProducts('', 1).then((r) => r || toast.success('Product is deleted'));
      } catch (error) {
        toast.error(error.message);
        console.error(error);
      }
    }
  };

  return (
    <>
      <h1 className="text-xl font-Montserrat">{t('Mange Products')}</h1>

      <div className="w-full px-4 mx-auto">
        <div className="py-8">
          <div className="flex flex-row justify-end w-full mb-1 sm:mb-0">
            <div className="text-end">
              <button
                className="flex-shrink-0 px-4 py-2 text-base font-Montserrat text-white bg-purple-600 rounded-lg shadow-md hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 focus:ring-offset-purple-200"
                onClick={() => {
                  navigate('/admin/products/new');
                }}>
                {t('Add Product')}
              </button>
            </div>
          </div>
          <div className="px-4 py-4 -mx-4 overflow-x-auto sm:-mx-8 sm:px-8">
            <div className="inline-block min-w-full overflow-hidden rounded-lg shadow">
              <table className="min-w-full leading-normal">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="px-5 py-3 text-sm font-normal text-left text-gray-800 uppercase bg-white border-b border-gray-200"
                    >
                      {t('Name')}
                    </th>
                    <th
                      scope="col"
                      className="px-5 py-3 text-sm font-normal text-left text-gray-800 uppercase bg-white border-b border-gray-200"
                    >
                      {t('Created at')}
                    </th>
                    <th
                      scope="col"
                      className="px-5 py-3 text-sm font-normal text-left text-gray-800 uppercase bg-white border-b border-gray-200"
                    ></th>
                  </tr>
                </thead>
                <tbody>
                  {isLoading || isFetching
                    ? (
                      <tr>
                        <td colSpan={5} className="text-center py-10 w-full">
                          Loading...
                        </td>
                      </tr>)
                    : isError
                      ? (
                        <ErrorMessage message={"Couldn't fetch the products data"} />
                      )
                      : (productsData?.data?.length === 0
                        ? (
                          <tr>
                            <td colSpan={5} className="text-center py-10 w-full">
                              {t('No products found')}
                            </td>
                          </tr>
                        )
                        : (
                          productsData?.data?.map((product, key) => (
                            <tr key={key.toString()}>
                              <td className="px-5 py-5 text-sm bg-white border-b border-gray-200">
                                <div className="flex items-center">
                                  <div className="flex-shrink-0">
                                    <a href="/" className="relative block">
                                      <img
                                        src={
                                          product?.image
                                            ? stables.UPLOAD_FOLDER_BASE_URL +
                                        product?.image
                                            : images.noImage
                                        }
                                        alt={productsData.data[key].names?.en}
                                        className="mx-auto object-contain w-12 aspect-square"
                                      />
                                    </a>
                                  </div>
                                  <div className="ml-3">
                                    <p className="text-gray-900 whitespace-no-wrap">
                                      {productsData.data[key].names?.en}
                                    </p>
                                  </div>
                                </div>
                              </td>
                              <td className="px-5 py-5 text-sm bg-white border-b border-gray-200">
                                <p className="text-gray-900 whitespace-no-wrap">
                                  {new Date(product.createdAt).toLocaleDateString(
                                    'en-US',
                                    {
                                      day: 'numeric',
                                      month: 'short',
                                      year: 'numeric'
                                    }
                                  )}
                                </p>
                              </td>
                              <td className="px-5 py-5 text-sm bg-white border-b border-gray-200 space-x-5">
                                <button
                                  disabled={isLoading}
                                  type="button"
                                  className="text-red-600 hover:text-red-900 disabled:opacity-70 disabled:cursor-not-allowed"
                                  onClick={() => {
                                    handleDeleteProduct({
                                      slug: product?.slug,
                                      token: userState.userInfo.token
                                    });
                                  }}
                                >
                                  Delete
                                </button>
                                <Link
                                  to={`/admin/products/manage/edit/${product?.slug}`}
                                  className="text-green-600 hover:text-green-900"
                                >
                                  Edit
                                </Link>
                              </td>
                            </tr>
                          )
                          )))}
                </tbody>
              </table>
              {!isLoading && productsData?.data && (
                <Pagination
                  onPageChange={(page) => setCurrentPage(page)}
                  currentPage={currentPage}
                  totalPageCount={JSON.parse(
                    productsData?.pageData?.totalPageCount
                  )}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ManageProducts;
