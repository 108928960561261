import React from 'react';
import draftToHtml from 'draftjs-to-html';
import { images, stables } from '../../constants';
import { useTranslation } from 'react-i18next';

export const ProductCard = ({ product, className }) => {
  const { i18n, t } = useTranslation();
  return (
    <div className={`overflow-hidden bg-[#F7F7F7] py-5 lg:py-10 px-5 lg:px-14 rounded-lg ${className}`} >
      <img
        src={ product.image ? stables.UPLOAD_FOLDER_BASE_URL + product.image : images.samplePostImage }
        alt="title"
        className="object-contain object-center w-auto mx-auto drop-shadow-[9px_10px_23px_rgba(0,0,0,0.25)]"
      />

      <div className="pt-10">
        <h2 className="drop-shadow-lg font-MontserratArmSemiBold font-bold text-black text-2xl pb-5 border-b border-[#717171] text-center">
          {t(product.names[i18n.language]) || product.name}
        </h2>
        <div
          className="product-card-desc text-black mt-5"
          dangerouslySetInnerHTML={{ __html: draftToHtml((product.descriptions[i18n.language] || product.descriptions.en) && JSON.parse(product.descriptions[i18n.language] || product.descriptions.en)) }}
        />
      </div>
    </div>
  );
};

export const ProductsCard = ({ product, className }) => {
  const { i18n, t } = useTranslation();
  return (
    <div className={`overflow-hidden bg-[#F7F7F7] py-10 px-14 rounded-lg ${className}`} >
      <img
        src={ product.image ? stables.UPLOAD_FOLDER_BASE_URL + product.image : images.samplePostImage }
        alt="title"
        className="object-contain object-center w-auto h-[400px] mx-auto drop-shadow-[9px_10px_23px_rgba(0,0,0,0.25)]"
      />

      <div className="pt-10">
        <h2 className="drop-shadow-lg font-MontserratArmSemiBold font-bold text-black text-2xl pb-5 border-b border-[#717171] text-center">
          {t(product.names[i18n.language]) || product.name}
        </h2>
        <div
          className="product-card-desc text-black mt-5"
          dangerouslySetInnerHTML={{ __html: draftToHtml((product.descriptions[i18n.language] || product.descriptions.en) && JSON.parse(product.descriptions[i18n.language] || product.descriptions.en)) }}
        />
      </div>
    </div>
  );
};
