import React from 'react';
import { images } from '../../../constants';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useTranslation } from 'react-i18next';

const Technology = () => {
  const { t } = useTranslation();

  return (
    <section className="relative w-full bg-white pt-20 z-20">
      <div className="container h-full flex flex-col justify-center">
        <h3 className="section-title mb-24 text-left font-MontserratArmSemiBold">{t('Our Technology')}</h3>
        <div className="flex flex-wrap lg:justify-center md:justify-start justify-center md:gap-x-5 gap-y-5 pb-10">
          <div className='bg-[#EFEFEF] flex flex-wrap justify-between w-full p-8 rounded-3xl gap-8'>
            <div className='lg:w-[calc(33.3333%-1.5rem)] w-full flex flex-col lg:items-start items-center gap-4'>
              <span className='self-start'>{t('Cigarette lines')}</span>
              <img className='h-[100px] w-max object-contain' src={images.Tech1} alt={t('Cigarette lines')}/>
            </div>
            <div className='lg:w-[calc(33.3333%-1.5rem)] w-full flex flex-col lg:items-start items-center gap-4'>
              <span className='self-start'>{t('Laboratory equipment')}</span>
              <img className='h-[100px] w-max object-contain' src={images.Tech2} alt={t('Laboratory equipment')}/>
            </div>
            <div className='lg:w-[calc(33.3333%-1.5rem)] w-full flex flex-col lg:items-start items-center gap-4'>
              <span className='self-start'>{t('Packaging lines')}</span>
              <img className='h-[100px] w-max object-contain' src={images.Tech3} alt={t('Packaging lines')}/>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Technology;
