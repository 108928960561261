import React, { useEffect, useRef, useState } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import { useTranslation } from 'react-i18next';
import { FaPause, FaPlay } from 'react-icons/fa';

gsap.registerPlugin(ScrollTrigger);

export const VideoContainer = ({ src }) => {
  const sectionRef = useRef(null);
  const videoRef = useRef(null);
  const videoContainerRef = useRef(null);
  const [isPaused, setIsPaused] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    const video = videoRef.current;
    gsap.to(
      video,
      {
        duration: 1,
        ease: 'power4.inOut',
        scrollTrigger: {
          trigger: sectionRef?.current,
          start: 'top bottom',
          end: 'bottom top',
          onToggle: (self) => {
            self.isActive ? video.play() : video.pause();
          }
        }
      });

    gsap.to(
      videoContainerRef.current,
      {
        duration: 1,
        ease: 'power4.inOut',
        scrollTrigger: {
          trigger: sectionRef?.current,
          start: 'top top',
          end: 'bottom bottom',
          pin: '.pin',
          toggleActions: 'play none none reverse',
          pinSpacing: 0,
          anticipatePin: 1
        }
      });

    const headings = gsap.utils.toArray('.text-container h3');
    headings.forEach((el, i) => {
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: '.move-' + (i + 1),
          start: 'center center',
          end: '+=100%',
          scrub: 3,
          preventOverlaps: false
        }
      });
      tl.fromTo(el, { opacity: 0, bottom: '-100%' }, { opacity: 0.6, bottom: 0, duration: 1, immediateRender: false, ease: 'sine.in' }, '-=0.5');
      tl.fromTo(el, { bottom: 0 }, { opacity: 1, bottom: '50%', duration: 2, immediateRender: false, ease: 'sine' }, '+=0.25');
      tl.to(el, { bottom: '100%', duration: 1, opacity: 0, ease: 'sine.out' }, '+=1');
    });

    return () => ScrollTrigger.getAll().forEach(t => t.kill());
  }, []);

  const videoControl = () => {
    if(videoRef.current?.paused) {
      setIsPaused(false);
      videoRef.current?.play();
    } else {
      setIsPaused(true);
      videoRef.current?.pause();
    }
  };

  return (
    <section ref={sectionRef} className='w-full relative min-h-screen z-10 -mt-16'>

      <div className='flex flex-row relative z-20'>
        <div className='pin min-h-screen absolute w-full top-0 left-0 z-20'>

          <div ref={videoContainerRef} className="absolute left-0 top-0 overflow-hidden mx-auto h-screen w-full">
            <video ref={videoRef} autoPlay={false} loop muted playsInline={true} className="absolute top-0 left-0 h-full w-full object-cover aspect-video">
              <source src={src} type="video/mp4"/>
              Your browser does not support the video tag.
            </video>
            <div className='absolute md:right-14 right-4 bottom-10 rounded-full bg-white60 text-white w-14 h-14 z-50 flex justify-center items-center' onClick={videoControl}>
              {isPaused ? <FaPlay/> : <FaPause/> }
            </div>
          </div>

          <div className='relative w-full max-w-[1200px] mx-auto h-full text-container flex flex-col justify-center items-center'>
            <h3 className=" z-20 max-w-[90%] md:px-32 px-2 drop-shadow-lg absolute -bottom-full text-[min(5vw,3rem)] text-center felx items-center text-white font-MontserratArmSemiBold">{t('MultiTabak leads the way with innovative tobacco processing technology')}</h3>
            <h3 className=" z-20 max-w-[90%] md:px-32 px-2 drop-shadow-lg absolute -bottom-full text-[min(5vw,3rem)] text-center felx items-center text-white font-MontserratArmSemiBold">{t('Our experts continuously refine and enhance the technology for optimal results.')}</h3>
            <h3 className=" z-20 max-w-[90%] md:px-32 px-2 drop-shadow-lg absolute -bottom-full text-[min(5vw,3rem)] text-center felx items-center text-white font-MontserratArmSemiBold">{t('Immerse yourself in the ultimate fusion of tradition and modernity.')}</h3>
            <h3 className=" z-20 max-w-[90%] md:px-32 px-2 drop-shadow-lg absolute -bottom-full text-[min(5vw,3rem)] text-center felx items-center text-white font-MontserratArmSemiBold"/>
          </div>

        </div>
        <div className='relative'>
          <section className="panel move-1 bg-gray min-h-screen"></section>
          <section className="panel move-2 bg-[blue] min-h-screen"></section>
          <section className="panel move-3 bg-[red] min-h-screen"></section>
          <section className="panel move-4 bg-[green] min-h-screen"></section>
        </div>
      </div>

    </section>
  );
};
