import React, { forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import ErrorMessage from '../../components/ErrorMessage';

import { getSingleBrand } from '../../services/index/brands';
import { toast } from 'react-hot-toast';
import ArticleDetailSkeleton from '../admin/components/ArticleDetailSkeleton';
import { images, stables } from '../../constants';
import ProductCardSkeleton from '../../components/product/ProductCardSkeleton';
import { ProductCard } from '../../components/product/ProductCard';
import { PageSection } from '../../components/section/SectionElements';
import { useTranslation } from 'react-i18next';
import { getProductsByBrand } from '../../services/index/products';
import videoMulti from '../../assets/videos/Multi Tabak.mp4';
import videoHatis from '../../assets/videos/Hatis.mp4';
import videoKars from '../../assets/videos/Kars.mp4';
import videoClass from '../../assets/videos/Multi_Class.mp4';
import { gsap } from 'gsap';

const createParticle = (x, y, parent) => {
  const size = Math.random() * 50 + 30;
  const bubble = getRandomElement();

  const particle = document.createElement('div');
  particle.className = 'bubble absolute w-12 h-12 bg-contain bg-no-repeat bg-center';
  parent?.appendChild(particle);

  gsap.fromTo(particle,
    {
      width: size,
      height: size,
      filter: `blur(${bubble.blur})`,
      backgroundImage: `url(${bubble.bg})`,
      top: y - size / 2,
      left: x - size / 2
    },
    {
      x: x + (Math.random() - 0.5) * 300,
      y: y + (Math.random() - 0.5) * 300,
      opacity: 0,
      scale: 0,
      duration: 2,
      ease: 'power4.easeOut',
      onComplete: () => {
        parent?.removeChild(particle);
      }
    });
};

const productType = {
  Multi_Tabak: { title: 'HFPT Products', video: videoMulti },
  Hatis: { title: 'A-plus Products', video: videoHatis },
  Kars: { title: 'SST Products', video: videoKars },
  Multi_Class: { title: 'Capsule Technology', video: videoClass }
};

const randomX = Math.floor(Math.random() * 70);
const randomY = Math.floor(Math.random() * 70);

const reviewBubbles = {
  0: { bg: images.BubbleBlue, blur: 0, zIndex: 2, x: randomX, y: randomY },
  1: { bg: images.BubblePurple, blur: 0, zIndex: 2, x: randomX, y: randomY },
  2: { bg: images.BubbleDarkBlue, blur: 0, zIndex: 2, x: randomX, y: randomY },
  3: { bg: images.BubbleBlue, blur: '2px', zIndex: 1, x: randomX, y: randomY },
  4: { bg: images.BubblePurple, blur: 0, zIndex: 2, x: randomX, y: randomY },
  5: { bg: images.BubbleDarkBlue, blur: '2px', zIndex: 1, x: randomX, y: randomY },
  6: { bg: images.BubbleBlue, blur: 0, zIndex: 2, x: randomX, y: randomY },
  7: { bg: images.BubblePurple, blur: '2px', zIndex: 1, x: randomX, y: randomY }
};

const getRandomElement = () => {
  const keys = Object.keys(reviewBubbles);
  const randomKey = keys[Math.floor(Math.random() * keys.length)];
  return reviewBubbles[randomKey];
};

const Anim = forwardRef(function Anim({ src }, ref) {
  return (
    <video ref={ref} autoPlay={true} loop muted playsInline={true}
      className="absolute top-0 left-0 h-full w-full object-cover aspect-video" src={src}/>
  );
});

const Bubble = forwardRef(function Bubble({ index, bubble }, ref) {
  return (
    <div
      ref={(el) => (ref.current[index] = el)}
      className={'bubble absolute w-12 h-12 bg-contain bg-no-repeat bg-center'}
      style={{
        backgroundImage: `url(${bubble.bg})`,
        filter: `blur(${bubble.blur})`,
        zIndex: bubble.zIndex,
        top: `${Math.floor(Math.random() * 70)}%`,
        left: `${Math.floor(Math.random() * 70)}%`
      }}
    />
  );
});

const groupItemsByName = (items) => {
  const groupedItems = {};

  items.forEach(item => {
    const name = item.names.en; // You can change 'en' to the language you prefer
    if(!groupedItems[name]) {
      groupedItems[name] = [];
    }
    groupedItems[name].push(item);
  });

  return groupedItems;
};

const BrandDetailPage = () => {
  const { slug } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [brand, setBrand] = useState(null);
  const [products, setProducts] = useState({ });
  const [videoSrc, setVideoSrc] = useState(null);
  const sectionRef = useRef(null);
  const videoRef = useRef(null);
  const bubbleContainer = useRef(null);
  const bubbleRefs = useRef([]);
  const { t, i18n } = useTranslation();

  const handleMouseMove = useCallback((e) => {
    const x = e.clientX;
    const y = e.clientY;
    createParticle(x, y, sectionRef.current);
  }, []);

  const handleTouchMove = useCallback((e) => {
    const x = e.touches[0].clientX;
    const y = e.touches[0].clientY;
    e.preventDefault();
    createParticle(x, y, sectionRef.current);
  }, []);

  useEffect(() => {
    const fetchBrand = async (slug) => {
      try {
        const { data, isLoading, isError } = await getSingleBrand({ slug });
        setBrand(data);
        setIsLoading(isLoading);
        setIsError(isError);
        fetchBrandProducts(data.id);
      } catch (error) {
        toast.error(error.message);
        console.error(error);
      }
    };
    const fetchBrandProducts = async (id) => {
      try {
        const { data, isLoading, isError } = await getProductsByBrand({ slug: id });
        setProducts(groupItemsByName(data));
        setIsLoading(isLoading);
        setIsError(isError);
        setVideoSrc(productType[slug].video);
      } catch (error) {
        toast.error(error.message);
        console.error(error);
      }
    };
    // Fetch studioWorks when the component mounts
    fetchBrand(slug);

    videoRef.current?.play();

    const sl = slug === 'Multi_Class';

    sl && window.addEventListener('mousemove', handleMouseMove);
    sl && document.body.addEventListener('touchmove', handleTouchMove);

    return () => {
      sl && window.removeEventListener('mousemove', handleMouseMove);
      sl && document.body.removeEventListener('touchmove', handleTouchMove);
    };
  }, [handleMouseMove, handleTouchMove, slug]);

  return (
    <>
      {isLoading && <ArticleDetailSkeleton />}
      {isError && <ErrorMessage message="Couldn't fetch the Brands section" /> }
      {!isLoading && !isError && brand &&
      <>
        <section ref={sectionRef} className="-mt-16 md:pt-40 pt-16 w-full md:h-auto min-h-screen bg-black overflow-hidden flex justify-center relative">
          {slug === 'Multi_Class' && <div ref={bubbleContainer} className='absolute w-full md:h-full h-screen left-0 top-0'>
            {Array.from({ length: 10 }).map((_, index) => (
              <Bubble key={index.toString()} ref={bubbleRefs} index={index} bubble={getRandomElement()}/>
            )
            )}
          </div>}
          {videoSrc && <Anim ref={videoRef} src={videoSrc} />}
          <div className='container z-10 flex md:flex-row flex-col md:gap-6 gap-0 justify-center items-center p-4 text-white'>
            <div className='md:w-1/2 w-full h-full flex flex-col justify-start md:gap-16 gap-8 items-center'>
              <img className='w-full max-w-[500px] h-[340px] object-contain' src={stables.UPLOAD_FOLDER_BASE_URL + brand.logo} alt="banner image"/>
              <p className='max-w-[500px] md:text-xl' dangerouslySetInnerHTML={{ __html: (brand.descriptions[i18n.language] || brand.description) }}/>
            </div>
            <div className='md:w-1/2 w-full md:block md:h-full h-1/2'>
              <img className='w-full h-full max-w-md object-contain mx-auto relative top-0 left-0' src={stables.UPLOAD_FOLDER_BASE_URL + brand.image} alt="banner image"/>
            </div>
          </div>
        </section>
        <PageSection className="mt-8">
          <div className="container">
            <h3 className="section-title text-left mb-20">{t(productType[slug].title)}</h3>
            <div className="flex flex-wrap md:justify-start justify-center md:gap-x-5 gap-y-5 pb-10">
              {isLoading && [...Array(3)].map((item, index) => (
                <ProductCardSkeleton
                  key={index}
                  className="w-full md:w-[calc(50%-20px)] lg:w-[calc(33.33%-21px)]"
                />
              ))}
              {isError && <ErrorMessage message="Couldn't fetch the product data" />}
              {!isLoading && !isError && !products && <h2 className="text-center w-full text-lg">{t('No Product Found')}</h2> }

              <div className='grid grid-cols-1 md:grid-cols-3 gap-5'>
                {!isLoading && !isError && products && slug !== 'Multi_Class' && Object.keys(products).map((name, key) => (
                  <div key={name} className='flex flex-col gap-5'>
                    { products[name].map((item, key) => <ProductCard
                      key={key.toString()}
                      product={item}
                      className="w-full flex flex-col justify-between min-h-[440px] lg:min-h-[480px]"
                    />)}
                  </div>
                )) }
                {!isLoading && !isError && products && slug === 'Multi_Class' && Object.keys(products).map((name, key) => (
                  products[name].map((item, key) => <ProductCard
                    key={key.toString()}
                    product={item}
                    className="w-full flex flex-col justify-between min-h-[440px] lg:min-h-[480px]"
                  />)
                )) }
              </div>
            </div>
          </div>
        </PageSection>
      </>
      }
    </>
  );
};

export default BrandDetailPage;
