import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import Header from './components/header/Header';
import { getUserProfile } from '../../services/index/users';
import { useSelector } from 'react-redux';
import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

const AdminLayout = () => {
  const navigate = useNavigate();
  const userState = useSelector((state) => state.user);
  const { i18n } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const { data, isLoading } = await getUserProfile({ token: userState.userInfo.token });
        setIsLoading(isLoading);
        if(!data?.admin) {
          navigate('/login');
          toast.error('Your are not allowed to access admin panel');
        }
      } catch (error) {
        navigate('/login');
        toast.error('You have no permission to login. ' + error.message);
      }
    };
    fetchUser();
  }, [navigate, userState?.userInfo?.token]);

  useEffect(() => {
    i18n.changeLanguage('en');
  }, [i18n]);

  if(isLoading) {
    return (
      <div className="w-full h-screen flex justify-center items-center">
        <h3 className="text-xl text-slate-700">Loading...</h3>
      </div>
    );
  }
  return (
    <div className="flex flex-col h-screen lg:flex-row">
      <Header />
      <main className="bg-[#F9F9F9] flex-1 p-4 lg:p-6 overflow-x-hidden">
        <Outlet />
      </main>
    </div>
  );
};

export default AdminLayout;
