import React, { useEffect, useRef, useState } from 'react';
import MainLayout from '../components/MainLayout';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import { userActions } from '../store/reducers/userReducers';
import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { getWebSettings } from '../services/index/webSettings';
import ArticleDetailSkeleton from './admin/components/ArticleDetailSkeleton';
import ErrorMessage from '../components/ErrorMessage';
import { sendMail } from '../services/index/sendMail';
import { useLocation } from 'react-router-dom';

const Contact = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [contactData, setContactData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [submitMessage, setSubmitMessage] = useState(null);
  const location = useLocation();
  const mapRef = useRef(null);

  useEffect(() => {
    const fetchContacts = async () => {
      try {
        const { data, isLoading, isError } = await getWebSettings();
        setContactData(data[0]);
        setIsLoading(isLoading);
        setIsError(isError);
      } catch (error) {
        toast.error(error.message);
      }
    };
    fetchContacts();
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if(mapRef.current && location.hash === '#map') {
        mapRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }, 1000);

    return () => clearTimeout(timeoutId);
  }, [location]);

  const { mutate } = useMutation({
    mutationFn: ({ data, subject }) => {
      return sendMail({ data, subject });
    },
    onSuccess: (data) => {
      dispatch(userActions.setUserInfo(data));
      setSubmitMessage(t('Form submitted successfully!'));
    },
    onError: (error) => {
      toast.error(error.message);
      setSubmitMessage(error.message);
      console.log(error);
    }
  });
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    defaultValues: {
      name: '',
      email: '',
      message: ''
    },
    mode: 'onChange'
  });
  const submitHandler = (data) => {
    const subject = 'MultiTabak.am: New Contact Form Submission';
    // Show loading state
    setLoading(true);
    mutate({ data, subject });
  };
  return (
    <MainLayout cta={false}>
      <section className="relative py-20 z-20 -mt-16">
        <div className="container h-full flex flex-col min-h-screen gap-8">
          <h3 className="section-title mb-0 text-left font-MontserratArmSemiBold">{t('Contact Us')}</h3>
          {isLoading && <ArticleDetailSkeleton />}
          {isError && (
            <div className='absolute w-full left-1/2 top-1/2 -translate-x-1/2'>
              <ErrorMessage message="Couldn't fetch the Contacts data" />
            </div>
          )}

          {!isLoading && !isError && contactData && (<>

            <div className="flex flex-wrap md:justify-between justify-center gap-8 pb-10">

              <div className='lg:w-[calc(50%-1rem)] w-full flex flex-col gap-6 justify-between'>
                <p className='max-w-[50%] max-w-1/2'>{t('feel free to contact us and we will get back to you as soon as we can.')}</p>
                <form onSubmit={handleSubmit(submitHandler)}>
                  <div className="flex flex-col mb-6 w-full">
                    <input
                      type="text"
                      id="name"
                      {...register('name', {
                        minLength: {
                          value: 1,
                          message: 'Name length must be at least 1 character'
                        },
                        required: {
                          value: true,
                          message: 'Name is required'
                        }
                      })}
                      placeholder={t('Enter name')}
                      className={`placeholder:text-[#636363] text-black mt-3 px-5 py-4 font-MontserratArmSemiBold block outline-none border-b ${
                        errors.name ? 'border-red-500' : 'border-black'
                      }`}
                    />
                    {errors.name?.message && (
                      <p className="text-red-500 text-xs mt-1">
                        {errors.name?.message}
                      </p>
                    )}
                  </div>
                  <div className="flex flex-col mb-6 w-full">
                    <input
                      type="email"
                      id="email"
                      {...register('email', {
                        pattern: {
                          value:
                          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                          message: 'Enter a valid email'
                        },
                        required: {
                          value: true,
                          message: 'Email is required'
                        }
                      })}
                      placeholder={t('Enter email')}
                      className={`placeholder:text-[#636363] text-black mt-3 px-5 py-4 font-MontserratArmSemiBold block outline-none border-b ${
                        errors.email ? 'border-red-500' : 'border-black'
                      }`}
                    />
                    {errors.email?.message && (
                      <p className="text-red-500 text-xs mt-1">
                        {errors.email?.message}
                      </p>
                    )}
                  </div>
                  <div className="flex flex-col mb-6 w-full">
                    <textarea
                      rows='1'
                      id="message"
                      {...register('message', {
                        required: {
                          value: true,
                          message: 'Message is required'
                        }
                      })}
                      placeholder={t('Your message')}
                      className={`placeholder:text-[#636363] text-black mt-3 px-5 py-4 font-MontserratArmSemiBold block outline-none border-b resize-none ${
                        errors.message ? 'border-red-500' : 'border-black'
                      }`}
                    />
                    {errors.message?.message && (
                      <p className="text-red-500 text-xs mt-1">
                        {errors.message?.message}
                      </p>
                    )}
                  </div>
                  {!submitMessage && <button
                    type="submit"
                    disabled={loading}
                    className="mt-8 bg-primary text-white font-MontserratArmSemiBold text-lg py-4 px-8 w-full rounded-lg disabled:opacity-70 disabled:cursor-not-allowed"
                  >
                    {loading ? t('Sending') : t('Send')}
                  </button>}

                  {submitMessage && <p className='mx-4 my-8 text-lg'>{submitMessage}</p>}
                </form>

              </div>
              <div className='lg:w-[calc(33.333%-1rem)] w-full flex flex-col gap-8 justify-between'>
                <div className='flex flex-wrap gap-8'>
                  <div className='flex flex-col'>
                    <span className='px-4 text-[#1B1B1B]'>{t('office opening hours')}</span>
                    <div className='flex flex-col'>
                      <span className='text-xl'>{t('office_working_days')}</span>
                      <span className='text-xl'>{t('office_working_hours')}</span>
                      <span className='text-xl'>{t('weekend closed')}</span>
                    </div>
                  </div>

                  <div className='flex flex-col'>
                    <span className='px-4 text-[#1B1B1B]'>{t('store opening hours')}</span>
                    <div className='flex flex-col'>
                      <span className='text-xl'>{t('store_working_days')}</span>
                      <span className='text-xl'>{t('store_working_hours')}</span>
                    </div>
                  </div>
                </div>
                <div className='flex flex-col'>
                  <span className='px-4 text-[#1B1B1B]'>{t('address')}</span>
                  <div className='flex flex-col'>
                    <span className='text-xl'>{t('Legal address')}</span>
                    <span className='text-xl'>{t('Activity address')}</span>
                    <span className='text-xl'>{t('Store address')}</span>
                  </div>
                </div>
                <div className='flex flex-col'>
                  <span className='px-4 text-[#1B1B1B]'>{t('support')}</span>
                  <div className='flex flex-col'>
                    <a className='text-xl' href={`phone:${contactData.phone}`}>{contactData.phone}</a>
                    <a className='text-xl' href={`mailto:${contactData.email}`}>{contactData.email}</a>
                  </div>
                </div>
              </div>

            </div>

            <div className='w-full gap-8 flex flex-col' ref={mapRef}>
              <h2 className='text-[#636363] font-MontserratArmSemiBold text-[1.875rem]'>{t('Find a nearby store')}</h2>
              <iframe
                src={contactData.mapSrc}
                className='w-full h-[450px] border-0'
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade">
              </iframe>
            </div>
          </>
          )}

        </div>
      </section>
    </MainLayout>
  );
};

export default Contact;
