import React from 'react';
import { images, stables } from '../constants';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const BrandCard = ({ item, className, ...rest }) => {
  const { i18n } = useTranslation();
  return (
    <Link
      {...rest}
      to={`/${i18n.language}/brand/${item.slug}`}
      className={`overflow-hidden grid justify-items-center gap-8 cursor-pointer ${className}`} >
      <img src={ stables.UPLOAD_FOLDER_BASE_URL + item.logo || images.Logo }
        alt="brand logo"
        className="brand-logo object-contain aspect-3/2 w-full max-w-[200px] self-center"
      />

      <img src={ stables.UPLOAD_FOLDER_BASE_URL + item.image || images.samplePostImage }
        alt="brand product"
        className="object-contain aspect-3/4 w-full self-end"
      />
    </Link>
  );
};

export default BrandCard;
