import axios from 'axios';

export const sendMail = async (formData) => {
  try {
    const { data } = await axios.post(`${process.env.REACT_APP_HOST}/api/send-email`, { formData });
    return { data };
  } catch (error) {
    if(error.response && error.response.data.message) { throw new Error(error.response.data.message); }
    throw new Error(error.message);
  }
};
