import React, { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { checkPass, getUserProfile, updateProfile } from '../../../../services/index/users';
import ProfilePicture from '../../../../components/ProfilePicture';
import { userActions } from '../../../../store/reducers/userReducers';
import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

const ProfilePage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const userState = useSelector((state) => state.user);
  const { t } = useTranslation();

  const { data: profileData, isLoading: profileIsLoading } = useQuery({
    queryFn: () => {
      return getUserProfile({ token: userState.userInfo.token });
    },
    queryKey: ['profile']
  });

  const { mutate, isLoading: updateProfileIsLoading } = useMutation({
    mutationFn: ({ name, email, password }) => {
      return updateProfile({
        token: userState.userInfo.token,
        userData: { name, email, password }
      });
    },
    onSuccess: (data) => {
      dispatch(userActions.setUserInfo(data));
      localStorage.setItem('account', JSON.stringify(data));
      queryClient.invalidateQueries(['profile']);
      toast.success('Profile is updated');
    },
    onError: (error) => {
      toast.error(error.message);
      console.log(error);
    }
  });

  useEffect(() => {
    if(!userState.userInfo) {
      navigate('/');
    }
  }, [navigate, userState.userInfo]);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    getValues
  } = useForm({
    defaultValues: {
      name: '',
      email: '',
      password: ''
    },
    values: useMemo(() => {
      return {
        name: profileIsLoading ? '' : profileData.name,
        email: profileIsLoading ? '' : profileData.email
      };
    }, [profileData?.email, profileData?.name, profileIsLoading]),
    mode: 'onChange'
  });

  const submitHandler = async (data) => {
    const { name, email, password, currentPassword } = data;

    try {
      const userData = { currentPassword };
      // Send a request to your server to verify the current password
      const response = await checkPass({ userData, token: userState.userInfo.token });
      if(response.status === 200) {
        // Current password is correct, proceed with the update
        mutate({ name, email, password });
      } else {
        // Current password is incorrect, show an error message
        toast.error('Current Password is incorrect');
      }
    } catch (error) {
      console.error('Error checking current password:', error);
    }
  };

  return (
    <section className="container mx-auto px-5 py-10">
      <div className="w-full max-w-sm mx-auto">
        <ProfilePicture avatar={profileData?.avatar} />
        <form onSubmit={handleSubmit(submitHandler)}>
          <div className="flex flex-col mb-6 w-full">
            <label
              htmlFor="name"
              className="text-[#5a7184] font-Montserrat block"
            >
              {t('Name')}
            </label>
            <input
              type="text"
              id="name"
              {...register('name', {
                minLength: {
                  value: 1,
                  message: 'Name length must be at least 1 character'
                },
                required: {
                  value: true,
                  message: 'Name is required'
                }
              })}
              placeholder={t('Enter name')}
              className={`placeholder:text-[#959ead] text-black mt-3 rounded-lg px-5 py-4 font-Montserrat block outline-none border ${
                errors.name ? 'border-red-500' : 'border-[#c3cad9]'
              }`}
            />
            {errors.name?.message && (
              <p className="text-red-500 text-xs mt-1">
                {errors.name?.message}
              </p>
            )}
          </div>
          <div className="flex flex-col mb-6 w-full">
            <label
              htmlFor="email"
              className="text-[#5a7184] font-Montserrat block"
            >
              {t('Email')}
            </label>
            <input
              type="email"
              id="email"
              {...register('email', {
                pattern: {
                  value:
                      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  message: 'Enter a valid email'
                },
                required: {
                  value: true,
                  message: 'Email is required'
                }
              })}
              placeholder={t('Enter email')}
              className={`placeholder:text-[#959ead] text-black mt-3 rounded-lg px-5 py-4 font-Montserrat block outline-none border ${
                errors.email ? 'border-red-500' : 'border-[#c3cad9]'
              }`}
            />
            {errors.email?.message && (
              <p className="text-red-500 text-xs mt-1">
                {errors.email?.message}
              </p>
            )}
          </div>
          {/* Current Password */}
          <div className="flex flex-col mb-6 w-full">
            <label
              htmlFor="oldPassword"
              className="text-[#5a7184] font-Montserrat block"
            >
              {t('Current Password')}
            </label>
            <input
              type="password"
              id="currentPassword"
              {...register('currentPassword', {
                required: {
                  value: true,
                  message: 'Current password is required'
                }
              })}
              placeholder={t('Enter current password')}
              className={`placeholder:text-[#959ead] text-black mt-3 rounded-lg px-5 py-4 font-Montserrat block outline-none border ${
                errors.currentPassword ? 'border-red-500' : 'border-[#c3cad9]'
              }`}
            />
            {errors.currentPassword?.message && (
              <p className="text-red-500 text-xs mt-1">
                {errors.currentPassword?.message}
              </p>
            )}
          </div>
          <div className="flex flex-col mb-6 w-full">
            <label
              htmlFor="password"
              className="text-[#5a7184] font-Montserrat block"
            >
              {t('New Password (optional)')}
            </label>
            <input
              type="password"
              id="password"
              {...register('password')}
              placeholder={t('Enter new password')}
              className={`placeholder:text-[#959ead] text-black mt-3 rounded-lg px-5 py-4 font-Montserrat block outline-none border ${
                errors.password ? 'border-red-500' : 'border-[#c3cad9]'
              }`}
            />
            {errors.password?.message && (
              <p className="text-red-500 text-xs mt-1">
                {errors.password?.message}
              </p>
            )}
          </div>
          {/* Repeat Password */}
          <div className="flex flex-col mb-6 w-full">
            <label
              htmlFor="repeatPassword"
              className="text-[#5a7184] font-Montserrat block"
            >
              {t('Repeat Password')}
            </label>
            <input
              type="password"
              id="repeatPassword"
              {...register('repeatPassword', {
                validate: (value) => {
                  if(value === getValues('password')) {
                    return true;
                  }
                  return 'Passwords must match';
                }
              })}
              placeholder={t('Repeat new password')}
              className={`placeholder:text-[#959ead] text-black mt-3 rounded-lg px-5 py-4 font-Montserrat block outline-none border ${
                errors.repeatPassword ? 'border-red-500' : 'border-[#c3cad9]'
              }`}
            />
            {errors.repeatPassword?.message && (
              <p className="text-red-500 text-xs mt-1">
                {errors.repeatPassword?.message}
              </p>
            )}
          </div>
          <button
            type="submit"
            disabled={!isValid || profileIsLoading || updateProfileIsLoading}
            className="bg-primary text-white font-bold text-lg py-4 px-8 w-full rounded-lg mb-6 disabled:opacity-70 disabled:cursor-not-allowed"
          >
            {t('Update')}
          </button>
        </form>
      </div>
    </section>
  );
};

export default ProfilePage;
