import axios from 'axios';

export const getAllProducts = async ({ searchKeyword = '', page = 1, limit = 10, where = false }) => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_HOST}/api/products?searchKeyword=${searchKeyword}&page=${page}&limit=${limit}&where=${where}`
    );
    return { data };
  } catch (error) {
    if(error.response && error.response.data.message) { throw new Error(error.response.data.message); }
    throw new Error(error.message);
  }
};

export const createProduct = async ({ newData, token }) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`
      }
    };
    return await axios.put(`${process.env.REACT_APP_HOST}/api/products/create`, newData, config);
  } catch (error) {
    if(error.response && error.response.data.message) { throw new Error(error.response.data.message); }
    throw new Error(error.message);
  }
};

export const getProductsByBrand = async ({ slug }) => {
  try {
    const { data } = await axios.get(`${process.env.REACT_APP_HOST}/api/products/products-by-brand/${slug}`);
    return { data };
  } catch (error) {
    if(error.response && error.response.data.message) { throw new Error(error.response.data.message); }
    throw new Error(error.message);
  }
};

export const getSingleProduct = async ({ slug }) => {
  try {
    const { data } = await axios.get(`${process.env.REACT_APP_HOST}/api/products/${slug}`);
    return { data };
  } catch (error) {
    if(error.response && error.response.data.message) { throw new Error(error.response.data.message); }
    throw new Error(error.message);
  }
};

export const deleteProduct = async ({ slug, token }) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };

    const { data } = await axios.delete(`${process.env.REACT_APP_HOST}/api/products/${slug}`, config);
    return data;
  } catch (error) {
    if(error.response && error.response.data.message) { throw new Error(error.response.data.message); }
    throw new Error(error.message);
  }
};

export const updateProduct = async ({ updatedData, slug, token }) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    const { data } = await axios.put(`${process.env.REACT_APP_HOST}/api/products/${slug}`, updatedData, config);
    return data;
  } catch (error) {
    if(error.response && error.response.data.message) { throw new Error(error.response.data.message); }
    throw new Error(error.message);
  }
};
