import axios from 'axios';

export const getWebSettings = async () => {
  try {
    const { data } = await axios.get(`${process.env.REACT_APP_HOST}/api/webSettings`);
    return { data };
  } catch (error) {
    if(error.response && error.response.data.message) { throw new Error(error.response.data.message); }
    throw new Error(error.message);
  }
};

export const updateWebSettings = async ({ updatedData, token }) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    const { data } = await axios.put(`${process.env.REACT_APP_HOST}/api/webSettings`, updatedData, config);
    return data;
  } catch (error) {
    if(error.response && error.response.data.message) { throw new Error(error.response.data.message); }
    throw new Error(error.message);
  }
};
