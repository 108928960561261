import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import SocialMediaInputs from './SocialMediaInputs';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-hot-toast';
import { getWebSettings, updateWebSettings } from '../../../../services/index/webSettings';
import { useSelector } from 'react-redux';
import ErrorMessage from '../../../../components/ErrorMessage';

const WebSiteSettingsPage = () => {
  const userState = useSelector((state) => state.user);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [webSettings, setWebSettings] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    const fetchWebSettings = async () => {
      try {
        const { data, isLoading, isError } = await getWebSettings();
        setWebSettings(data[0]);
        setIsLoading(isLoading);
        setIsError(isError);
      } catch (error) {
        toast.error(error.message);
        console.error(error);
      }
    };
    fetchWebSettings();
  }, []);

  const {
    register,
    formState: { errors },
    handleSubmit
  } = useForm({
    defaultValues: {
      email: '',
      phone: '',
      mapSrc: '',
      socialLinks: {
        instagram: '',
        facebook: '',
        twitter: '',
        youtube: ''
      }
    },
    values: useMemo(() => {
      return (webSettings && Object.keys(webSettings).length > 0) && {
        email: webSettings.email ? webSettings.email : '',
        phone: webSettings.phone ? webSettings.phone : '',
        mapSrc: webSettings.mapSrc ? webSettings.mapSrc : '',
        socialLinks: {
          instagram: webSettings.socialLinks?.instagram ? webSettings.socialLinks.instagram : '',
          facebook: webSettings.socialLinks?.facebook ? webSettings.socialLinks.facebook : '',
          twitter: webSettings.socialLinks?.twitter ? webSettings.socialLinks.twitter : '',
          youtube: webSettings.socialLinks?.youtube ? webSettings.socialLinks.youtube : ''
        }
      };
    }, [webSettings]),
    mode: 'onChange'
  });

  const onSubmit = async (data) => {
    try {
      const updatedData = data;

      if(updatedData) {
        setIsLoading(true);
        return await updateWebSettings({ updatedData, token: userState.userInfo.token }).then(() => {
          toast.success('Contacts updated successfully');
          setIsLoading(false);
        });
      }
    } catch (error) {
      toast.error(error.message);
      console.error(error);
    }
  };

  return (
    <section className="container mx-auto max-w-5xl px-5 py-5 lg:flex-row lg:gap-x-5 lg:items-start">
      <h1 className="text-xl font-Montserrat mb-4">{('Contacts')}</h1>

      {isLoading && <div className="text-center py-10 w-full">
        Loading...
      </div>}

      {isError && <ErrorMessage message="Couldn't fetch the contacts" /> }

      {!isLoading && !isError && (
        <form onSubmit={handleSubmit(onSubmit)} className='flex-1'>
          <div className='w-full flex md:flex-row flex-col gap-4 my-4'>
            <div className="flex flex-col md:w-1/2 w-full">
              <label htmlFor="companyEmail" className='text-[#5a7184] font-Montserrat block'>{t('Company Email')}</label>
              <input
                type="email"
                id="email"
                name="email"
                {...register('email', {
                  pattern: {
                    value:
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: 'Enter a valid email'
                  },
                  required: {
                    value: false,
                    message: 'Email is required'
                  }
                })}
                placeholder={t('Enter email')}
                className={`placeholder:text-[#959ead] text-black mt-3 rounded-lg px-5 py-4 font-Montserrat block outline-none border ${
                  errors.email ? 'border-red-500' : 'border-[#c3cad9]'
                }`}
              />
              {errors.email?.message && (
                <p className="text-red-500 text-xs mt-1">
                  {errors.email?.message}
                </p>
              )}
            </div>
            <div className="flex flex-col md:w-1/2 w-full">
              <label htmlFor="phoneNumber" className='text-[#5a7184] font-Montserrat block'>{t('Phone Number')}</label>
              <input
                type="tel"
                id="phoneNumber"
                name="phone"
                placeholder={t('Enter phone number')}
                {...register('phone')}
                className={`placeholder:text-[#959ead] text-black mt-3 rounded-lg px-5 py-4 font-Montserrat block outline-none border ${
                  errors.phoneNumber ? 'border-red-500' : 'border-[#c3cad9]'
                }`}
              />
            </div>

          </div>
          <div className='w-full flex md:flex-row flex-col gap-4 my-4'>
            <div className="flex flex-col md:w-1/2 w-full">
              <label htmlFor="mapSrc" className='text-[#5a7184] font-Montserrat block'>{t('Nearby Stores Map Url')}</label>
              <textarea
                rows='1'
                id="mapSrc"
                name="mapSrc"
                {...register('mapSrc', { required: { value: false } })}
                placeholder={t('Enter nearby stores map url')}
                className={`placeholder:text-[#959ead] text-black mt-3 rounded-lg px-5 py-4 font-Montserrat block outline-none border min-h-[58px] ${
                  errors.mapSrc ? 'border-red-500' : 'border-[#c3cad9]'
                }`}
              />
              {errors.mapSrc?.message && (
                <p className="text-red-500 text-xs mt-1">
                  {errors.mapSrc?.message}
                </p>
              )}
            </div>
          </div>
          <SocialMediaInputs register={register} t={t} />
          <div className='w-full text-end'>
            <button
              // disabled={isLoading}
              type="submit"
              className="bg-green-500 text-white hover:bg-green-500/90 font-Montserrat rounded-lg px-8 py-2 my-4 disabled:cursor-not-allowed disabled:opacity-70"
            >
              {t('Save')}
            </button>
          </div>
        </form>)
      }
    </section>
  );
};

export default WebSiteSettingsPage;
