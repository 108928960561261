import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import './i18n';

import store from './store';

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

  <BrowserRouter
    future={{ v7_startTransition: true }}>
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <Suspense fallback={<div className='h-screen w-screen fixed top-0 left-0 flex items-center justify-center'>LOADING...</div>}>
          <App/>
        </Suspense>
      </QueryClientProvider>
    </Provider>
  </BrowserRouter>
);
