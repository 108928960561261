import React, { useEffect, useRef } from 'react';
import { images } from '../../../constants';
import { useTranslation } from 'react-i18next';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

const Hero = () => {
  const bannerTitle = useRef(null);
  const sectionRef = useRef(null);
  const logoRef = useRef(null);
  const { t } = useTranslation();

  useEffect(() => {
    const secRef = sectionRef.current;
    const lRef = logoRef.current;

    if(typeof window !== 'undefined') {
      gsap.registerPlugin(ScrollTrigger);
    }

    const tl = gsap.timeline();

    tl.fromTo(
      bannerTitle.current,
      {
        scale: 0.3,
        // paddingTop: '20%',
        opacity: 1
      },
      {
        scale: 0,
        opacity: 0,
        // paddingTop: 0,
        duration: 1,
        ease: 'none',
        scrollTrigger: {
          trigger: secRef,
          start: 'top top-=0',
          end: '+=700',
          scrub: 1

        }
      }, 0
    );

    tl.fromTo(
      lRef,
      {
        scale: window.innerWidth < 641 ? 3 : 5,
        y: 130
      },
      {
        scale: 1,
        y: 0,
        duration: 1,
        ease: 'sine',
        scrollTrigger: {
          trigger: secRef,
          start: 'top top',
          end: 'bottom top',
          anticipatePin: 1,
          pin: true,
          scrub: 1
          // pinSpacing: false,
        }
      }
    );
    return () => {
      gsap.killTweensOf(lRef); // Clean up animations on unmount
      gsap.killTweensOf(secRef); // Clean up animations on unmount
      ScrollTrigger.killAll();
    };
  }, [sectionRef]);

  return (
    <section ref={sectionRef} className="hero relative z-20 -mt-16 bg-white min-h-screen w-full">
      <div className="flex flex-col min-h-[100vh] justify-center items-center relative overflow-hidden">

        <div ref={logoRef} className='relative flex justify-center items-center'>
          <h4
            ref={bannerTitle}
            className='-md:mt-4 absolute lg:top-[38%] md:top-[37%] top-[39%] w-full lg:max-w-[670px] max-w-[500px] md:h-[80px] h-[60px] text-center lg:text-5xl md:text-4xl text-3xl md:leading-normal font-MontserratArmSemiBold'>{t('Elevate Your Smoking Experience To New Heights')}</h4>

          <img src={images.Banner} alt="" className='mLogo max-w-full h-[100vh] pt-20 object-cover'/>
        </div>
        <div className='w-full flex flex-col items-center justify-center z-10' >
          <img className='drop-shadow max-w-[150px]' src={images.BrandMultiBlack} alt="Multi Tabak Logo"/>
          <h1 className='banner-title my-4 font-MontserratArmSemiBold'>{t('We have the taste.')}</h1>
        </div>
      </div>
    </section>
  );
};

export default Hero;
