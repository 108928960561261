import React from 'react';
import { images } from '../../../constants';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useTranslation } from 'react-i18next';

const RoadMap = () => {
  const { t } = useTranslation();

  return (
    <section className="relative w-full bg-white min-h-screen pt-20 z-20">
      <div className="min-h-[80vh] h-full flex flex-col justify-center py-16 px-8 gap-12 bg-fixed bg-cover bg-no-repeat bg-center shadow-[inset_0_0_0_100vh_rgba(0,0,0,0.3)]" style={{ backgroundImage: `url(${images.Banner2})` }}>
        <div className="container flex flex-wrap md:justify-between justify-center w-full gap-y-8">
          <div className='text-white px-4 md:w-1/4 sm:w-1/2 w-full'>
            <span className='block px-4 mb-8'>{t('project capacity')}</span>
            <h3 className='font-MontserratArmSemiBold uppercase border-l-4 px-4 text-xl'>{t('up to 20 billion cigarettes per year')}</h3>
          </div>
          <div className='text-white px-4 md:w-1/4 sm:w-1/2 w-full'>
            <span className='block px-4 mb-8'>{t('production and warehouse areas')}</span>
            <h3 className='font-MontserratArmSemiBold uppercase border-l-4 px-4 text-xl'>{t('24000 M2')}</h3>
          </div>
          <div className='text-white px-4 md:w-1/4 sm:w-1/2 w-full'>
            <span className='block px-4 mb-8'>{t('suppliers of tobacco raw materials')}</span>
            <h3 className='font-MontserratArmSemiBold uppercase border-l-4 px-4 text-xl'>{t('leading manufacturers Asia, Africa and Latin America')}</h3>
          </div>
          <div className='text-white px-4 md:w-1/4 sm:w-1/2 w-full'>
            <span className='block px-4 mb-8'>{t('investments')}</span>
            <h3 className='font-MontserratArmSemiBold uppercase border-l-4 px-4 text-xl'>{t('110 million euros')}</h3>
          </div>
        </div>
        <div className="container flex flex-wrap md:justify-start justify-center w-full gap-y-8">
          <div className='text-white px-4 md:w-1/4 sm:w-1/2 w-full'>
            <span className='block px-4 mb-8'>{t('produced formats')}</span>
            <h3 className='font-MontserratArmSemiBold uppercase border-l-4 px-4 text-xl'>{t('King Size, Queen Size, Nano (King Size Super Slims), Super Slims, Slim')}</h3>
          </div>
          <div className='text-white px-4 md:w-1/4 sm:w-1/2 w-full'>
            <span className='block px-4 mb-8'>{t('research complex')}</span>
            <h3 className='font-MontserratArmSemiBold uppercase border-l-4 px-4 text-xl'>{t('laboratory equipment Borgwaldt company, full compliance requirements ISO and CORESTA')}</h3>
          </div>
          <div className='text-white px-4 md:w-1/4 sm:w-1/2 w-full'>
            <span className='block px-4 mb-8'>{t('production launch')}</span>
            <h3 className='font-MontserratArmSemiBold uppercase border-l-4 px-4 text-xl'>{t('January 2023')}</h3>
          </div>
        </div>
      </div>
    </section>
  );
};

export default RoadMap;
