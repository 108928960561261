import React, { useEffect, useState } from 'react';
import ErrorMessage from '../../../components/ErrorMessage';
import { toast } from 'react-hot-toast';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { useTranslation } from 'react-i18next';
import WorkPhoto from '../components/WorkPhoto';
import ArticleDetailSkeleton from '../components/ArticleDetailSkeleton';
import { getGallery } from '../../../services/index/gallery';

const Gallery = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [gallery, setGallery] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    const fetchGallery = async () => {
      try {
        const { data, isLoading, isError } = await getGallery();
        setGallery(data.gallery);
        setIsLoading(isLoading);
        setIsError(isError);
      } catch (error) {
        toast.error(error.message);
        console.error(error);
      }
    };
    // Fetch studioWorks when the component mounts
    fetchGallery();
  }, []);

  return (
    <>
      {isLoading && <ArticleDetailSkeleton />}

      {isError && <ErrorMessage message="Couldn't fetch the gallery" /> }

      {!isLoading && !isError && gallery && (
        <section className="container mx-auto flex flex-col items-stretch px-5 py-5 gap-8">
          <h3 className="section-title mb-0 text-left font-MontserratArmSemiBold">{t('Gallery')}</h3>
          <div className='flex-1 gap-4 flex flex-col'>
            <div className='flex justify-center flex-wrap gap-4'>
              <WorkPhoto gallery={gallery?.image1} slug={'1'}/>
              <WorkPhoto gallery={gallery?.image2} slug={'2'}/>
              <WorkPhoto gallery={gallery?.image3} slug={'3'}/>
              <WorkPhoto gallery={gallery?.image4} slug={'4'}/>
              <WorkPhoto gallery={gallery?.image5} slug={'5'}/>
              <WorkPhoto gallery={gallery?.image6} slug={'6'}/>
            </div>
          </div>
        </section>
      )
      }
    </>
  );
};

export default Gallery;
