import { useNavigate } from 'react-router-dom';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { toast } from 'react-hot-toast';
import { useForm } from 'react-hook-form';
import { createProduct } from '../../../../services/index/products';
import { NavBtn } from '../../../../components/navBar/NavbarElements';
import { locales } from '../../../../components/PageData';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import { FaCircleXmark } from 'react-icons/fa6';
import { getAllBrands } from '../../../../services/index/brands';
import { images } from '../../../../constants';
import { Editor } from 'react-draft-wysiwyg';
import { convertFromRaw, convertToRaw, EditorState } from 'draft-js';

const ImageUpload = ({ image, handleFileChange, handleDeleteImage, id, label }) => (
  <label htmlFor={id} className="md:w-1/2 w-full cursor-pointer px-4">
    <span className="text-[#5a7184] font-Montserrat block my-4">{label}</span>
    <div className="w-full md:m-0 mx-auto md bg-white rounded-lg overflow-hidden items-center">
      <div className="px-4 py-6 h-80 flex justify-center items-center">
        <label id={id} className="w-full relative h-full p-6 m-4 bg-gray-100 border-dashed border-2 border-gray-400 rounded-lg flex flex-col items-center justify-center text-center cursor-pointer">
          <input
            id={`upload-${id}`}
            type="file"
            className="hidden"
            onChange={handleFileChange}
            accept="image/*" />
          {image
            ? (
              <img src={URL.createObjectURL(image)} className="max-h-full mx-auto bg-contain bg-center" alt="" style={{ backgroundImage: `url(${images.TransparentBg})` }} />

            )
            : (<>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                stroke="currentColor" className="w-8 h-8 text-gray-700 mx-auto mb-4">
                <path strokeLinecap="round" strokeLinejoin="round"
                  d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5m-13.5-9L12 3m0 0l4.5 4.5M12 3v13.5"/>
              </svg>
              <h5 className="mb-2 text-xl font-bold tracking-tight text-gray-700">Upload picture</h5>
            </>
            )}
          {image && <FaCircleXmark
            onClick={handleDeleteImage}
            className="bg-white text-red-500 text-3xl absolute -top-4 -right-4"/>}
        </label>
      </div>
    </div>
  </label>
);

const NewProduct = () => {
  const queryClient = useQueryClient();
  const dropdownRef = useRef(null);
  const userState = useSelector((state) => state.user);
  const [image, setImage] = useState(null);
  const [profileDropdown, setProfileDropdown] = useState(false);
  const [brandDropdown, setBrandDropdown] = useState(false);
  const [currentLng, setCurrentLng] = useState(Object.keys(locales)[0]);
  const [formData, setFormData] = useState({ names: {}, descriptions: {} });
  const [brands, setBrands] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState({ name: '', id: '' });
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const [editor, setEditor] = useState(EditorState.createEmpty());

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm({
    defaultValues: {
      name: '',
      image: ''
    },
    mode: 'onChange'
  });

  const {
    mutate: mutateUpdateProductDetail,
    isLoading: isLoadingUpdateProductDetail
  } = useMutation({
    mutationFn: ({ newData, token }) => {
      return createProduct({
        newData,
        token
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['products']);
      toast.success('Product is created');
      setTimeout((navigate('/admin/products/manage')), 800);
    },
    onError: (error) => {
      toast.error(error.message);
      console.log(error);
    }
  });

  useEffect(() => {
    const fetchBrands = async () => {
      try {
        const { data } = await getAllBrands('', '');
        const bData = data.result;
        setBrands(bData);
      } catch (error) {
        toast.error(error.message);
        console.error(error);
      }
    };
    fetchBrands();

    // Add the event listener when the component mounts
    document.addEventListener('click', handleClickOutside);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setCurrentLng(i18n.language);
  }, [i18n.language]);

  // Close the dropdown when clicking outside of it
  const handleClickOutside = (event) => {
    if(dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setBrandDropdown(false);
    }
  };

  const handleImageChange = (e) => {
    const image = e.target.files[0];
    setImage(image);
  };

  const changeLanguage = async (data) => {
    const content = editor ? JSON.stringify(convertToRaw(editor.getCurrentContent())) : '';

    setFormData({ names: { ...formData.names, [currentLng]: data.data.name }, descriptions: { ...formData.descriptions, [currentLng]: content } });
    setCurrentLng(data.locale);
    formData && reset({ name: formData.names[data.locale] || '', description: formData.descriptions[data.locale] || '' });
    const editorState = formData.descriptions[data.locale] && EditorState.createWithContent(convertFromRaw(JSON.parse(formData.descriptions[data.locale])));
    setEditor(editorState);
  };
  const handleBrandSelection = (item) => {
    setSelectedBrand({ name: item.name, id: item._id });
    setBrandDropdown(false);
  };

  const saveState = () => {
    if(formData && formData.bodies && formData.descriptions[currentLng]) {
      const editorState = EditorState.createWithContent(convertFromRaw(JSON.parse(formData.descriptions[currentLng])));
      setEditor(editorState);
    }
  };

  const onEditorStateChange = (editorState) => {
    setEditor(editorState);
  };

  const handleCreateProduct = useCallback(async (data) => {
    const newData = new FormData();

    // Check if there's a new photo, if so, append it
    if(image) {
      newData.append('productImage', image);
    }

    // Create a new data object with the current language data merged
    // const arr = { ...formData, [currentLng]: data, brand_id: selectedBrand.id };
    const content = JSON.stringify(convertToRaw(editor.getCurrentContent()));

    const arr = { names: { ...formData.names, [currentLng]: data.name }, descriptions: { ...formData.descriptions, [currentLng]: content }, brand_id: selectedBrand.id };
    newData.append('document', JSON.stringify({ data: arr }));

    // Trigger the update using the optimized data
    mutateUpdateProductDetail({
      newData,
      token: userState.userInfo.token
    });
  }, [currentLng, editor, formData.descriptions, formData.names, image, mutateUpdateProductDetail, selectedBrand.id, userState.userInfo.token]);

  const handleDeleteImage = () => {
    if(window.confirm('Do you want to delete your Product Image?')) {
      setImage(null);
    }
  };
  return (
    <section className="container mx-auto max-w-5xl flex flex-col px-5 py-5 lg:flex-row lg:gap-x-5 lg:items-start">
      <article className="flex-1">
        <div className='flex flex-wrap'>
          <ImageUpload
            image={image}
            id={'productImage'}
            label={'Product Image'}
            handleFileChange={handleImageChange}
            handleDeleteImage={handleDeleteImage}
          />
        </div>
        <div className='flex justify-end w-full my-4'>
          <div className="text-white items-center gap-y-5 lg:text-black flex flex-col lg:flex-row gap-x-2 font-Montserrat">
            <div className="relative group">
              <div className="flex flex-col items-center">
                <NavBtn onClick={() => setProfileDropdown(!profileDropdown)}>
                  <span>{locales[currentLng].name}</span>
                  <MdKeyboardArrowDown/>
                </NavBtn>
                <div
                  className={`${
                    profileDropdown ? 'block' : 'hidden'
                  } z-20 transition-all duration-500 pt-4 absolute bottom-0 right-0 transform translate-y-full group-hover:block w-max`}
                >
                  <ul
                    className="bg-white text-black text-center flex flex-col shadow-lg rounded-lg overflow-hidden w-full">
                    {Object.keys(locales).map((locale) => (
                      <NavBtn
                        key={locale}
                        onClick={handleSubmit((data) => changeLanguage({ data, locale }))}
                        className={`hover:bg-[#a5a5a5] hover:text-white px-4 py-2 text-black text-center cursor-pointer uppercase group ${currentLng === locale ? 'font-bold' : 'font-normal'}`}>
                        <p>{locales[locale].name}</p>
                      </NavBtn>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='flex flex-col mb-6 w-full'>
          <label htmlFor="brand" className="text-[#5a7184] font-Montserrat block">
            Brand
          </label>
          <div className="text-white items-center gap-y-5 lg:text-black flex flex-col lg:flex-row gap-x-2 font-Montserrat">
            <div id="brand" className="relative w-full">
              <div ref={dropdownRef} className="flex flex-col">
                <NavBtn
                  className={`w-full flex justify-between bg-white items-center text-black mt-3 rounded-lg px-5 py-4 font-Montserrat outline-none border ${
                    errors.brand_id ? 'border-red-500' : 'border-[#c3cad9]'}`}
                  {...register('brand_id', {
                    required: {
                      value: !selectedBrand.name,
                      message: 'Brand is required'
                    }
                  })}
                  onClick={() => setBrandDropdown(!brandDropdown)}>
                  <span>{selectedBrand.name || 'Select Brand'}</span>
                  <MdKeyboardArrowDown/>
                </NavBtn>
                {errors.brand_id?.message && (
                  <p className="text-red-500 text-xs mt-1">{errors.brand_id?.message}</p>
                )}
                <div className={`${brandDropdown ? 'block' : 'hidden'} z-20 w-full transition-all duration-500 pt-4 absolute bottom-0 right-0 transform translate-y-full`} >
                  <ul className="bg-white text-black text-left flex flex-col shadow-lg rounded-lg overflow-hidden w-full">
                    {brands.map((brand) => (
                      <button
                        key={brand._id}
                        onClick={() => handleBrandSelection(brand)}
                        className={`hover:bg-[#a5a5a5] hover:text-white px-4 py-2 text-left cursor-pointer uppercase group ${selectedBrand.name === brand.names.en ? 'bg-[#a5a5a5] text-white' : 'text-black'}`}>
                        <p>{brand.names.en}</p>
                      </button>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col mb-6 w-full">
          <label
            htmlFor="name"
            className="text-[#5a7184] font-Montserrat block"
          >
            Name
          </label>
          <input
            type="text"
            id="name"
            {...register('name', {
              minLength: {
                value: 1,
                message: 'Name length must be at least 1 character'
              },
              required: {
                value: currentLng === 'en',
                message: 'Name is required'
              }
            })}
            placeholder="Enter Name"
            className={`placeholder:text-[#959ead] text-black mt-3 rounded-lg px-5 py-4 font-Montserrat block outline-none border ${
              errors.name ? 'border-red-500' : 'border-[#c3cad9]'
            }`}
          />
          {errors.name?.message && (
            <p className="text-red-500 text-xs mt-1">
              {errors.name?.message}
            </p>
          )}
        </div>
        <div className="flex flex-col mb-6 w-full">
          <label
            htmlFor="editor"
            onClick={saveState}
            className="text-[#5a7184] font-Montserrat block"
          >Description</label>
          <Editor
            editorState={editor}
            editorClassName="bg-white text-black mt-3 rounded-lg px-5 py-4 block outline-none border border-slate-300"
            onEditorStateChange={onEditorStateChange}
          />
        </div>
        <button
          disabled={isLoadingUpdateProductDetail}
          type="button"
          onClick={handleSubmit(handleCreateProduct)}
          className="w-full bg-green-500 text-white hover:bg-green-500/90 font-Montserrat rounded-lg px-4 py-2 disabled:cursor-not-allowed disabled:opacity-70"
        >
          Create Product
        </button>
      </article>
    </section>
  );
};

export default NewProduct;
