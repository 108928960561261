import React from 'react';
import MainLayout from '../../components/MainLayout';
import Hero from './container/Hero';
import Media from './container/Media';
import Brands from './container/Brands';
import Technology from './container/Technology';
import MultiClassReview from './container/MultiClassReview';

const HomePage = () => {
  return (
    <MainLayout>
      <Hero/>
      <Media/>
      <Brands />
      <Technology/>
      <MultiClassReview/>
    </MainLayout>
  );
};

export default HomePage;
