import React from 'react';
import ErrorMessage from '../../../components/ErrorMessage';
import { images } from '../../../constants';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useTranslation } from 'react-i18next';

const Partners = () => {
  const isLoading = false;
  const isError = false;
  const { t } = useTranslation();

  return (
    <section className="relative w-full bg-white pt-20 z-20">
      <div className="container h-full flex flex-col justify-center">
        <h3 className="section-title mb-24 text-left font-MontserratArmSemiBold">{t('Our Construction Partners')}</h3>
        <div className="flex flex-wrap lg:justify-center md:justify-start justify-center md:gap-x-5 gap-y-5 pb-10">
          {isLoading
            ? (<div>loading</div>)
            : isError
              ? (
                <ErrorMessage message="Couldn't fetch the Technology" />
              )
              : (
                <div className='bg-[#EFEFEF] flex flex-col justify-between w-full p-12 rounded-3xl gap-12'>
                  <div className='w-full flex flex-wrap justify-between lg:items-start items-center gap-4'>
                    <img className='h-[70px] md:w-max w-[calc(50%-0.5rem)] object-contain' src={images.Riedel} alt=''/>
                    <img className='h-[70px] md:w-max w-[calc(50%-0.5rem)] object-contain' src={images.Hormann} alt=''/>
                    <img className='h-[70px] md:w-max w-[calc(50%-0.5rem)] object-contain' src={images.Kaeser} alt=''/>
                    <img className='h-[70px] md:w-max w-[calc(50%-0.5rem)] object-contain' src={images.Voith} alt=''/>
                    <img className='h-[70px] md:w-max w-[calc(50%-0.5rem)] object-contain' src={images.Pedroll} alt=''/>
                    <img className='h-[70px] md:w-max w-[calc(50%-0.5rem)] object-contain' src={images.Donfiss} alt=''/>
                  </div>
                  <div className='w-full flex flex-col gap-8'>
                    <span className='self-start'>{t('Cigarette line aspiration system')}</span>
                    <div className='w-full flex flex-wrap justify-between lg:items-start items-center gap-3'>
                      <img className='h-[68px] md:w-max w-[calc(50%-0.5rem)] object-contain' src={images.Beta} alt=''/>
                      <img className='h-[68px] md:w-max w-[calc(50%-0.5rem)] object-contain' src={images.Clark} alt=''/>
                      <img className='h-[68px] md:w-max w-[calc(50%-0.5rem)] object-contain' src={images.Skf} alt=''/>
                      <img className='h-[68px] md:w-max w-[calc(50%-0.5rem)] object-contain' src={images.Hikvision} alt=''/>
                    </div>
                  </div>
                </div>
              )}
        </div>
      </div>
    </section>
  );
};

export default Partners;
