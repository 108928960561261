import React from 'react';
import {
  SidebarContainer,
  Icon,
  CloseIcon,
  SidebarWrapper,
  SidebarMenu

} from './SidebarElements';
import { navItemsInfo, locales } from '../PageData';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { NavItem, NavLinks } from '../navBar/NavbarElements';

export const Sidebar = ({ isOpen, toggle }) => {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { lang } = useParams();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    navigate(lang ? location.pathname.replace(lang, lng) : `/${lng}${location.pathname}`);
  };
  return (
    <SidebarContainer isOpen={isOpen}>
      <Icon onClick={toggle}>
        <CloseIcon/>
      </Icon>
      <SidebarWrapper>
        <SidebarMenu>
          <ul className='flex w-full ml-auto py-2 justify-end border-b-2'>
            {Object.keys(locales).map((locale) => (
              <li
                key={locale}
                onClick={() => changeLanguage(locale)}
                className={`text-center cursor-pointer group w-[40px] text-dark-hard last:border-0 border-r-2 ${i18n.language === locale ? 'font-bold' : 'font-normal'}`}>
                <p className='select-none group-hover:md:font-bold'>{locales[locale].title}</p>
              </li>
            ))}
          </ul>
          <ul className='flex flex-col w-full py-0 justify-center border-b-2'>
            {Object.entries(navItemsInfo)?.map((navItem, key) => (
              <NavItem key={key.toString()} className='text-center justify-end cursor-pointer group w-full text-dark-hard last:border-0 border-b-2'>
                <NavLinks navLinkItem={navItem[1]}/>
              </NavItem>
            ))}
          </ul>
        </SidebarMenu>
      </SidebarWrapper>
    </SidebarContainer>
  );
};
