import React, { useState } from 'react';

import MainLayout from '../components/MainLayout';
import { PageSection } from '../components/section/SectionElements';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-hot-toast';
import { useMutation } from '@tanstack/react-query';
import { userActions } from '../store/reducers/userReducers';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { images } from '../constants';
import { sendMail } from '../services/index/sendMail';

const variants = {
  Paper: [{ image: images.Paper1, name: 'White' }, { image: images.Paper2, name: 'Grey with lines' }, { image: images.Paper3, name: 'Black' }],
  'Tipping paper': [{ image: images.Filter1, name: 'Classic' }, { image: images.Filter2, name: 'White foil' }, { image: images.Filter3, name: 'Black (with foil)' }],
  'Filter rods': [{ image: images.Fl1, name: 'Monoacetate' }, { image: images.Fl2, name: 'Hollow/tube' }, { image: images.Fl3, name: 'Recessed' }, { image: images.Fl4, name: 'Single capsule' }, { image: images.Fl5, name: 'Double capsule' }],
  Leaf: [{ image: images.Leaf1, name: 'American blend' }, { image: images.Leaf2, name: 'Virginia blend' }, { image: images.Leaf3, name: 'Multi blend' }],
  Pack: [{ image: images.Pack1, name: 'King size' }, { image: images.Pack2, name: 'Queen size' }, { image: images.Pack3, name: 'Nano size' }, { image: images.Pack4, name: 'Slim size' }, { image: images.Pack5, name: 'Super slim size' }]
};

const Personalize = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [submitMessage, setSubmitMessage] = useState(null);

  const { mutate } = useMutation({
    mutationFn: ({ data, subject }) => {
      return sendMail({ data, subject });
    },
    onSuccess: (data) => {
      dispatch(userActions.setUserInfo(data));
      setSubmitMessage(t('Form submitted successfully!'));
    },
    onError: (error) => {
      toast.error(error.message);
      setSubmitMessage(error.message);
      console.log(error);
    }
  });

  const {
    handleSubmit,
    control,
    setValue,
    watch,
    register,
    formState: { errors }
  } = useForm();

  const submitHandler = (data) => {
    const subject = 'MultiTabak.am: New Order Form Submission';
    // Show loading state
    setLoading(true);
    mutate({ data, subject });
  };

  return (
    <MainLayout>
      <PageSection className="bg-white">
        <div className="container">
          <div className='flex md:flex-row flex-col md:gap-6 gap-0 justify-center items-center'>
            <div className='md:w-1/2 w-full h-full flex flex-col justify-start'>
              <h3 className="section-title text-left mb-16">{t('Our Technology')}</h3>
              <h5 className='md:max-w-[500px] w-full md:mb-16 mb-0 text-[#1B1B1B] whitespace-pre-line'>{t('Make your product truly your own with personalized engravings. Customizable options available to cater to your individual preferences. Embrace a smoking experience uniquely tailored to you.')}</h5>
            </div>
            <div className='md:w-1/2 w-full md:block md:h-full h-1/2'>
              <img className='w-full h-full max-w-md object-contain mx-auto' src={images.Cigarette} alt="Cigarette image"/>
            </div>
          </div>
          <div className="py-10">

            <form className='flex flex-wrap gap-8' onSubmit={handleSubmit(submitHandler)}>

              {Object.entries(variants).map(([variant, images]) => (
                <div key={variant} className='lg:w-[calc(20%-1.6rem)] w-[calc(50%-1rem)] md:gap-8 gap-4 flex flex-col h-full'>
                  <p className='w-full text-[#121212] mb-4 font-MontserratArmSemiBold'>{t(variant)}</p>
                  {images.map((img, index) => (
                    <label key={index.toString()} className={`flex justify-center items-center md:p-8 p-4 w-full md:h-[250px] h-[160px] hover:bg-[#D9D9D9] rounded-[20px] cursor-pointer ${watch(variant) === img.name ? 'bg-[#D9D9D9]' : ''}`}>
                      <Controller
                        name={variant}
                        control={control}
                        defaultValue={img.name === images[0].name ? img.name : ''}
                        render={({ field }) => (
                          <input
                            type="radio"
                            {...field}
                            value={img.name}
                            onChange={() => setValue(variant, img.name)}
                            checked={watch(variant) === img.name}
                            className='hidden'
                          />
                        )} />
                      <div className='grid grid-rows-[73%_15%] h-full w-full gap-4'>
                        <img src={img.image} alt={img.name} className='self-center justify-self-center max-h-full w-full max-w-[200px] object-contain drop-shadow-[10px_16px_32px_rgba(0,0,0,0.30)] user-drag-none'/>
                        <p className='self-end'>{t(img.name)}</p>
                      </div>
                    </label>
                  ))}
                </div>
              ))}

              <div className='flex flex-wrap w-full justify-between mt-4'>
                <div className="lg:w-[calc(50%-1rem)] flex flex-col mb-6 w-full">
                  <input
                    type='number'
                    id="nicotine"
                    {...register('nicotine', {
                      min: {
                        value: 1,
                        message: 'Value must be greater then or equal to 1'
                      }
                    })}
                    placeholder={t('Nicotine resin')}
                    className={`placeholder:text-[#636363] text-black mt-3 px-5 py-4 font-MontserratArmSemiBold block outline-none border-b resize-none ${
                      errors.nicotine ? 'border-red-500' : 'border-black'
                    }`}
                  />
                  {errors.nicotine?.message && (
                    <p className="text-red-500 text-xs mt-1">
                      {errors.nicotine?.message}
                    </p>
                  )}
                </div>
                <div className="lg:w-[calc(50%-1rem)] flex flex-col mb-6 w-full">
                  <textarea
                    rows='1'
                    id="message"
                    {...register('message', {
                      required: {
                        value: false,
                        message: 'Message is required'
                      }
                    })}
                    placeholder={t('Your message')}
                    className={`placeholder:text-[#636363] text-black mt-3 px-5 py-4 font-MontserratArmSemiBold block outline-none border-b resize-none ${
                      errors.message ? 'border-red-500' : 'border-black'
                    }`}
                  />
                  {errors.message?.message && (
                    <p className="text-red-500 text-xs mt-1">
                      {errors.message?.message}
                    </p>
                  )}
                </div>
                <div className="lg:w-[calc(50%-1rem)] flex flex-col mb-6 w-full">
                  <input
                    type="text"
                    id="name"
                    {...register('name', {
                      minLength: {
                        value: 1,
                        message: 'Name length must be at least 1 character'
                      },
                      required: {
                        value: true,
                        message: 'Name is required'
                      }
                    })}
                    placeholder={t('Enter name')}
                    className={`placeholder:text-[#636363] text-black mt-3 px-5 py-4 font-MontserratArmSemiBold block outline-none border-b ${
                      errors.name ? 'border-red-500' : 'border-black'
                    }`}
                  />
                  {errors.name?.message && (
                    <p className="text-red-500 text-xs mt-1">
                      {errors.name?.message}
                    </p>
                  )}
                </div>
                <div className="lg:w-[calc(50%-1rem)] flex flex-col mb-6 w-full">
                  <input
                    type="email"
                    id="email"
                    {...register('email', {
                      pattern: {
                        value:
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                        message: 'Enter a valid email'
                      },
                      required: {
                        value: true,
                        message: 'Email is required'
                      }
                    })}
                    placeholder={t('Enter email')}
                    className={`placeholder:text-[#636363] text-black mt-3 px-5 py-4 font-MontserratArmSemiBold block outline-none border-b ${
                      errors.email ? 'border-red-500' : 'border-black'
                    }`}
                  />
                  {errors.email?.message && (
                    <p className="text-red-500 text-xs mt-1">
                      {errors.email?.message}
                    </p>
                  )}
                </div>
              </div>

              {!submitMessage &&
                <button
                  type="submit"
                  disabled={loading}
                  className="lg:max-w-[50%] mt-8 bg-[#EFEFEF] font-MontserratArmSemiBold text-lg py-4 px-8 w-full rounded-lg disabled:opacity-70 disabled:cursor-not-allowed"
                >
                  {loading ? t('submitting') : t('submit')}
                </button>
              }
            </form>

            {submitMessage && <p className='mx-4 my-8 text-lg'>{submitMessage}</p>}

          </div>
        </div>
      </PageSection>
    </MainLayout>);
};

export default Personalize;
