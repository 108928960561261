import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ArticleDetailSkeleton from '../../components/ArticleDetailSkeleton';
import ErrorMessage from '../../../../components/ErrorMessage';
import { stables } from '../../../../constants';
import { toast } from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { getSingleProduct, updateProduct } from '../../../../services/index/products';
import { NavBtn } from '../../../../components/navBar/NavbarElements';
import { locales } from '../../../../components/PageData';
import { useTranslation } from 'react-i18next';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { FaCircleXmark } from 'react-icons/fa6';
import { getAllBrands } from '../../../../services/index/brands';
import { Editor } from 'react-draft-wysiwyg';
import { convertFromRaw, convertToRaw, EditorState } from 'draft-js';

const ImageUpload = ({ image, initialImage, handleFileChange, handleDeleteImage, id, label, alt }) => (
  <label htmlFor={id} className="md:w-1/2 w-full cursor-pointer px-4">
    <span className="text-[#5a7184] font-Montserrat block my-4">{label}</span>
    <div className="w-full md:m-0 mx-auto md bg-white rounded-lg overflow-hidden items-center">
      <div className="px-4 py-6 h-80 flex justify-center items-center">
        <label id={id} className="w-full relative h-full p-6 m-4 bg-gray-100 border-dashed border-2 border-gray-400 rounded-lg flex flex-col items-center justify-center text-center cursor-pointer">
          <input
            id={`upload-${id}`}
            type="file"
            className="hidden"
            onChange={handleFileChange}
            accept="image/*" />
          {image
            ? (
              <img src={URL.createObjectURL(image)} className="max-h-full mx-auto" alt={alt}/>
            )
            : initialImage
              ? (
                <img src={initialImage} className="max-h-full mx-auto" alt={alt}/>

              )
              : (<>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                  stroke="currentColor" className="w-8 h-8 text-gray-700 mx-auto mb-4">
                  <path strokeLinecap="round" strokeLinejoin="round"
                    d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5m-13.5-9L12 3m0 0l4.5 4.5M12 3v13.5"/>
                </svg>
                <h5 className="mb-2 text-xl font-bold tracking-tight text-gray-700">Upload picture</h5>
              </>
              )}
          {(image || initialImage) && <FaCircleXmark
            onClick={handleDeleteImage}
            className="bg-white text-red-500 text-3xl absolute -top-4 -right-4"/>}
        </label>
      </div>
    </div>
  </label>
);

const EditProduct = () => {
  const { slug } = useParams();
  const dropdownRef = useRef(null);
  // eslint-disable-next-line no-unused-vars
  const userState = useSelector((state) => state.user);
  const [initialImage, setInitialImage] = useState(null);
  const [image, setImage] = useState(null);
  const [profileDropdown, setProfileDropdown] = useState(false);
  const [brandDropdown, setBrandDropdown] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [isImageRemoved, setIsImageRemoved] = useState(false);
  const [productData, setProductData] = useState(null);
  const [brands, setBrands] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState({ name: '', id: '' });
  const [currentLng, setCurrentLng] = useState(Object.keys(locales)[0]);
  const [formData, setFormData] = useState({});
  const [editor, setEditor] = useState(EditorState.createEmpty());
  const navigate = useNavigate();

  const { t, i18n } = useTranslation();

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const { data, isLoading, isError } = await getSingleProduct({ slug });
        setProductData(data);
        setFormData({ names: data.names, descriptions: data.descriptions });
        setSelectedBrand({ name: data?.brand?.name, id: data?.brand?.id });
        setIsLoading(isLoading);
        setIsError(isError);
      } catch (error) {
        toast.error(error.message);
        console.error(error);
      }
    };
    const fetchBrands = async () => {
      try {
        const { data } = await getAllBrands('', '');
        const bData = data.result;
        setBrands(bData);
      } catch (error) {
        toast.error(error.message);
        console.error(error);
      }
    };
    fetchBrands();
    fetchProducts();

    // Add the event listener when the component mounts
    document.addEventListener('click', handleClickOutside);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [slug]);

  useEffect(() => {
    setCurrentLng(i18n.language);

    const EditState = productData?.descriptions[i18n.language] && EditorState.createWithContent(convertFromRaw(JSON.parse(productData.descriptions[i18n.language])));
    setEditor(EditState);
  }, [i18n.language, productData?.descriptions]);

  const changeLanguage = async (data) => {
    const { name, locale } = data;
    const defState = productData.descriptions[currentLng] && EditorState.createWithContent(convertFromRaw(JSON.parse(productData.descriptions[currentLng])));
    const content = editor ? JSON.stringify(convertToRaw(editor.getCurrentContent())) : defState || '';
    setFormData({ names: { ...formData.names, [currentLng]: name }, descriptions: { ...formData.descriptions, [currentLng]: content } });
    setCurrentLng(locale);
    formData && reset({ name: formData.names[data.locale] || '', description: formData.descriptions[data.locale] || '' });
    const editorState = formData.descriptions[data.locale] && EditorState.createWithContent(convertFromRaw(JSON.parse(formData.descriptions[data.locale])));
    setEditor(editorState);
  };

  const handleClickOutside = (event) => {
    if(dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setBrandDropdown(false);
    }
  };

  const handleBrandSelection = (item) => {
    setSelectedBrand({ name: item.name, id: item._id });
    setBrandDropdown(false);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm({
    values: useMemo(() => {
      return {
        name: isLoading ? '' : formData?.names[currentLng] || productData?.names[currentLng]
        // description: isLoading ? '' : formData?.descriptions[currentLng] || productData?.descriptions[currentLng]
      };
    }, [currentLng, formData, isLoading, productData]),
    mode: 'onChange'
  });

  useEffect(() => {
    if(productData) {
      setInitialImage(productData?.image ? stables.UPLOAD_FOLDER_BASE_URL + productData?.image : null);
    }
  }, [reset, productData, currentLng, formData]);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImage(file);
  };

  const handleUpdateProduct = async (data) => {
    const updatedData = new FormData();

    const content = JSON.stringify(convertToRaw(editor.getCurrentContent()));

    // Check if there's a new image, if so, append it
    if(image) {
      updatedData.append('productImage', image);
    }
    const { name } = data;
    const arr = { names: { ...formData.names, [currentLng]: name }, descriptions: { ...formData.descriptions, [currentLng]: content }, brand_id: selectedBrand.id, isImageRemoved: image === null && initialImage === null && isImageRemoved };

    updatedData.append('document', JSON.stringify({ data: arr }));

    try {
      await updateProduct({ updatedData, slug, token: userState.userInfo.token });

      setTimeout((navigate('/admin/products/manage')), 1800);
      return toast.success('Product is updated');
    } catch (error) {
      toast.error(error.message);
      console.error(error);
    }
  };

  const handleDeleteImage = () => {
    if(window.confirm('Do you want to delete your Product picture?')) {
      setImage(null);
      setInitialImage(null);
      setIsImageRemoved(true);
    }
  };

  const saveState = () => {
    if(formData && formData.bodies && formData.descriptions[currentLng]) {
      const editorState = EditorState.createWithContent(convertFromRaw(JSON.parse(formData.descriptions[currentLng])));
      setEditor(editorState);
    }
  };

  const onEditorStateChange = (editorState) => {
    setEditor(editorState);
  };

  return (
    <>
      {isLoading
        ? (
          <ArticleDetailSkeleton />
        )
        : isError
          ? (
            <ErrorMessage message="Couldn't fetch the product detail" />
          )
          : (<section className="container mx-auto max-w-5xl flex flex-col px-5 py-5 lg:flex-row lg:gap-x-5 lg:items-start">
            <article className="flex-1">
              <div className='flex flex-wrap'>
                <ImageUpload
                  image={image}
                  id={'productImage'}
                  label={'Product Image'}
                  initialImage={initialImage}
                  alt={productData?.name}
                  handleFileChange={handleImageChange}
                  handleDeleteImage={handleDeleteImage}
                />
              </div>
              <div className='flex justify-end w-full my-4'>
                <div
                  className="text-white items-center gap-y-5 lg:text-black flex flex-col lg:flex-row gap-x-2 font-Montserrat">
                  <div className="relative group">
                    <div className="flex flex-col items-center">
                      <NavBtn onClick={() => setProfileDropdown(!profileDropdown)}>
                        <span>{locales[currentLng].name}</span>
                        <MdKeyboardArrowDown/>
                      </NavBtn>
                      <div
                        className={`${
                          profileDropdown ? 'block' : 'hidden'
                        } z-20 transition-all duration-500 pt-4 absolute bottom-0 right-0 transform translate-y-full group-hover:block w-max`}
                      >
                        <ul
                          className="bg-dark-light text-center flex flex-col shadow-lg rounded-lg overflow-hidden w-full">
                          {Object.keys(locales).map((locale) => (
                            <NavBtn
                              key={locale}
                              onClick={handleSubmit((data) => changeLanguage({ ...data, locale }))}
                              className={`hover:bg-[#a5a5a5] hover:text-white px-4 py-2 text-black text-center cursor-pointer uppercase group ${currentLng === locale ? 'font-bold' : 'font-normal'}`}>
                              <p>{locales[locale].name}</p>
                            </NavBtn>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='flex flex-col mb-6 w-full'>
                <label htmlFor="brand" className="text-[#5a7184] font-Montserrat block">
                  Brand
                </label>
                <div className="text-white items-center gap-y-5 lg:text-black flex flex-col lg:flex-row gap-x-2 font-Montserrat">
                  <div id="brand" className="relative w-full">
                    <div ref={dropdownRef} className="flex flex-col">
                      <NavBtn
                        className={`w-full flex justify-between bg-white items-center text-black mt-3 rounded-lg px-5 py-4 font-Montserrat outline-none border ${
                          errors.brand_id ? 'border-red-500' : 'border-[#c3cad9]'}`}
                        {...register('brand_id', {
                          required: {
                            value: !selectedBrand.name,
                            message: 'Brand is required'
                          }
                        })}
                        onClick={() => setBrandDropdown(!brandDropdown)}>
                        <span>{selectedBrand.name || 'Select Brand'}</span>
                        <MdKeyboardArrowDown/>
                      </NavBtn>
                      {errors.brand_id?.message && (
                        <p className="text-red-500 text-xs mt-1">{errors.brand_id?.message}</p>
                      )}
                      <div className={`${brandDropdown ? 'block' : 'hidden'} z-20 w-full transition-all duration-500 pt-4 absolute bottom-0 right-0 transform translate-y-full`} >
                        <ul className="bg-white text-black text-left flex flex-col shadow-lg rounded-lg overflow-hidden w-full">
                          {brands.map((brand) => (
                            <button
                              key={brand._id}
                              onClick={() => handleBrandSelection(brand)}
                              className={`hover:bg-[#a5a5a5] hover:text-white px-4 py-2 text-left cursor-pointer uppercase group ${selectedBrand.name === brand.names.en ? 'bg-[#a5a5a5] text-white' : 'text-black'}`}>
                              <p>{brand.names.en}</p>
                            </button>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col mb-6 w-full">
                <label
                  htmlFor="name"
                  className="text-[#5a7184] font-Montserrat block"
                >
                  Name
                </label>
                <input
                  type="text"
                  id="name"
                  {...register('name', {
                    minLength: {
                      value: 1,
                      message: 'Name length must be at least 1 character'
                    },
                    required: {
                      value: false,
                      message: 'Name is required'
                    }
                  })}
                  placeholder="Enter Name"
                  className={`placeholder:text-[#959ead] text-black mt-3 rounded-lg px-5 py-4 font-Montserrat block outline-none border ${
                    errors.name ? 'border-red-500' : 'border-[#c3cad9]'
                  }`}
                />
                {errors.name?.message && (
                  <p className="text-red-500 text-xs mt-1">
                    {errors.name?.message}
                  </p>
                )}
              </div>
              <div className="flex flex-col mb-6 w-full">
                <label
                  htmlFor="editor"
                  onClick={saveState}
                  className="text-[#5a7184] font-Montserrat block"
                >Description</label>
                <Editor
                  editorState={editor}
                  editorClassName="bg-white text-black mt-3 rounded-lg px-5 py-4 block outline-none border border-slate-300"
                  onEditorStateChange={onEditorStateChange}
                />
              </div>
              <button
                disabled={isLoading}
                type="button"
                onClick={handleSubmit(handleUpdateProduct)}
                className="w-full bg-green-500 text-white hover:bg-green-500/90 font-Montserrat rounded-lg px-4 py-2 disabled:cursor-not-allowed disabled:opacity-70"
              >
                {t('Save')}
              </button>
            </article>
          </section>
          )
      }
    </>
  );
};

export default EditProduct;
