import React, { useEffect, useState } from 'react';
import { animateScroll as scroll } from 'react-scroll';
import {
  MobileIcon, Nav,
  NavbarContainer,
  NavItem,
  NavLinks,
  NavLogo,
  NavMenu
} from './navBar/NavbarElements';

import { images } from '../constants';
import { FaBars } from 'react-icons/fa';
import { locales } from './PageData';
import { useTranslation } from 'react-i18next';

const Header = ({ toggle, navItems }) => {
  const [scrollNav, setScrollNav] = useState(false);
  const { i18n } = useTranslation();
  const changeNav = () => {
    if(window.scrollY >= 64) {
      setScrollNav(true);
    } else {
      setScrollNav(false);
    }
  };

  const toggleHome = () => {
    scroll.scrollToTop();
  };

  useEffect(() => {
    window.addEventListener('scroll', changeNav);
  }, []);

  const linkProps = {
    activeclass: 'active',
    duration: 500,
    exact: 'true',
    offset: -64
  };

  return (
    <Nav scrollNav={scrollNav}>
      <NavbarContainer>
        <NavLogo to={`/${i18n.language}`} onClick={toggleHome}>
          <img className="w-full h-full max-w-[170px]" src={images.Logo} alt="logo"/>
        </NavLogo>
        <MobileIcon onClick={toggle}>
          <FaBars/>
        </MobileIcon>
        <NavMenu>
          {Object.entries(navItems)?.map((navItem, key) => (
            <NavItem key={key.toString()}>
              <NavLinks {...linkProps} navLinkItem={navItem[1]} onClick={navItem[0] === 'home' ? toggleHome : null }/>
            </NavItem>
          ))}
          <NavItem>
            <NavLinks navLinkItem={{ locales, type: 'locales' }}/>
          </NavItem>
        </NavMenu>
      </NavbarContainer>
    </Nav>

  );
};

export default Header;
