import React, { useEffect, useState } from 'react';
import { images } from '../constants';
import { NavItem, NavLinks } from './navBar/NavbarElements';
import { animateScroll as scroll } from 'react-scroll';

const Footer = ({ navItems }) => {
  const [currentYear, setCurrentYear] = useState(20);

  useEffect(() => {
    setCurrentYear(new Date().getFullYear());
  }, []);

  const toggleHome = () => {
    scroll.scrollToTop();
  };

  return (
    <section className="bg-gray">
      <footer className="container flex flex-wrap justify-between items-center px-5 py-10 w-full gap-8">

        <ul className="footer-element-container md:flex grid md:grid-cols-2 grid-cols-1">
          {Object.entries(navItems)?.map((navItem, key) => {
            const item = navItem[0] === 'products' ? ['Products', { href: '/products', type: 'link ', name: 'All Products' }] : navItem;
            return (
              <NavItem key={key.toString()} className='text-dark-gray font-MontserratArmSemiBold w-fit'>
                <NavLinks navLinkItem={item[1]} onClick={item[0] === 'home' ? toggleHome : null }/>
              </NavItem>
            );
          }
          )}
        </ul>

        <div className="lg:w-1/4 w-full flex md:justify-end justify-start gap-12 h-[70px]">
          <img
            src={images.FooterLogo1}
            alt="logo"
            className="object-contain aspect-square max-w-[80px]"
          />
          <img
            src={images.LogoDark}
            alt="logo"
            className="object-contain aspect-square max-w-[80px]"
          />
        </div>
      </footer>
      <div className="bg-[#313131] text-white py-2 md:flex flex-col items-center space-y-4 text-center">
        <span className="text-sm">&copy; {currentYear} Multi Tabak - All rights reserved </span>
      </div>
    </section>
  );
};

export default Footer;
