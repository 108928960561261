import React, { useEffect, useRef, useState } from 'react';
import { images } from '../../../constants';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { toast } from 'react-hot-toast';
import { getAllProducts } from '../../../services/index/products';
import { useTranslation } from 'react-i18next';
import ArticleDetailSkeleton from '../../admin/components/ArticleDetailSkeleton';
import ErrorMessage from '../../../components/ErrorMessage';
import { Link } from 'react-router-dom';

const sections = ['A-plus quality system', 'SST- Tobacco Technology', 'HFPT technology', 'Multi Class - Capsule Technology'];

const dataImageMapEn = new Map([
  ['HATIS Black Sea', images.HatisBlackSeaEn],
  ['HATIS Blue Sky', images.HatisBlueSkyEn],
  ['HATIS White Moon', images.HatisWhiteMoonEn],
  ['KARS Dream Valley', images.KarsDreamValleyEn],
  ['KARS Mystic Valley', images.KarsMysticValleyEn],
  ['KARS Diamond Valley', images.KarsDiamondValleyEn],
  ['Multi Tabak South', images.MultiTabakSouthEn],
  ['Multi Tabak North', images.MultiTabakNorthEn],
  ['Multi Tabak Arctic', images.MultiTabakArcticEn],
  ['Multi Class Emerald', images.EmeraldEn],
  ['Multi Class Ruby', images.RubyEn],
  ['Multi Class Amethyst', images.AmethystEn],
  ['Multi Class Turmaline', images.MultiClassTurmalineEn],
  ['Multi Class Amethyst SS', images.MultiClassSSAmethystEn],
  ['Multi Class Aquamarine', images.AquamarineEn]
]);

const Technology = () => {
  const sectionRef = useRef(null);
  const techRefs = useRef([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [productsData, setProductsData] = useState(null);
  const { i18n, t } = useTranslation();
  // const { UPLOAD_FOLDER_BASE_URL } = stables;

  useEffect(() => {
    // Fetch aboutUss when the component mounts
    fetchProducts();
  }, [i18n.language]);
  const fetchProducts = async () => {
    try {
      const { data, isLoading, isError } = await getAllProducts({ limit: 100, where: true });
      setProductsData(data.result);
      setIsLoading(isLoading);
      setIsError(isError);
    } catch (error) {
      toast.error(error.message);
      console.error(error);
    }
  };

  useEffect(() => {
    const secRef = sectionRef.current;
    const tRef = techRefs.current;

    if(typeof window !== 'undefined') {
      gsap.registerPlugin(ScrollTrigger);
    }

    if(!isLoading) {
      const tl = gsap.timeline({ defaults: { duration: 4, ease: 'power1.inOut', opacity: 1, background: '#fff', stagger: 2 } });

      tRef.map((item, index) => {
        return tl.fromTo(item, { opacity: 1, xPercent: index * (-100), yPercent: 0 },
          {
            // opacity: index < 3 ? 0 : 1,
            yPercent: index < 3 ? -100 : 0
          });
      });

      ScrollTrigger.create({
        animation: tl,
        trigger: secRef,
        start: 'top top', // Adjust the start trigger as needed
        // end: sectionRef.current.offset * 2,
        end: '+=400%',
        // toggleActions: 'play none none reverse',
        pin: true,
        scrub: 2,
        anticipatePin: 1,
        pinSpacing: false
      });
    }

    return () => {
      gsap.killTweensOf(secRef); // Clean up animations on unmount
      gsap.killTweensOf(tRef);
    };
  }, [isLoading]);

  return (
    <section ref={sectionRef} className='p-0 overflow-hidden bg-[#FFFFFF] min-h-[400vh]'>
      {isLoading && <ArticleDetailSkeleton /> }
      {isError && (
        <div className='absolute w-full left-1/2 top-1/2 -translate-x-1/2'>
          <ErrorMessage message="Couldn't fetch the Technologies section" />
        </div>
      )}

      {!isLoading && !isError && sections && (

        <div className="h-[110vh] w-[400vw] flex">
          {sections?.map((section, index) => (
            <div ref={(el) => (techRefs.current[index] = el)} key={index.toString()} className={`tec tec-${index} z-${60 / (index + 1)} w-screen min-h-screen pt-16 pb-4 `}>
              <div className="container">
                <h3 className='section-title mb-16 pt-16'>{t(section)}</h3>

                <div className={`grid gap-8 md:grid-cols-3 grid-cols-2 h-[75vh] ${index < 3 ? 'grid-rows-2' : 'md:grid-rows-2 grid-rows-3'}`}>
                  {productsData.splice(0, index < 3 ? 3 : 6).map((item, key) => {
                    return (
                      <Link
                        to={`/${i18n.language}/products`}
                        key={key.toString()}
                        className={`bg-[#EFEFEF] overflow-hidden rounded-3xl px-6 py-4 flex flex-col gap-4 text-3xl items-start justify-center ${index < 3 ? index % 2 === 0 ? 'first:text-6xl first:row-start-1 md:first:row-end-3 first:row-end-2 first:col-start-1 first:col-end-3' : 'last:text-6xl last:row-start-1 md:last:row-end-3 last:row-end-2 md:last:col-start-2 last:col-start-1 md:last:col-end-4 last:col-end-3' : ''}`}>

                        <h3 className="font-MontserratArmSemiBold md:text-size-inherit leading-[1] text-lg w-full md:h-[20%] ">{item.names[i18n.language]}</h3>
                        <img src={dataImageMapEn.get(key === 4 ? item.name + ' SS' : item.name)} alt={item.name} className="md:aspect-square object-contain max-h-[70%] m-auto scale-150 relative top-[20%]"/>
                      </Link>
                    );
                  }
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>

      )
      }
    </section>
  );
};

export default Technology;
