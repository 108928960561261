import React from 'react';
import MainLayout from '../../components/MainLayout';
import Media from './container/Media';
import { VideoContainer } from './container/VideoContainer';
import Video from '../../video/video.mp4';
import Technology from './container/Technology';
import Partners from './container/Partners';
import RoadMap from './container/RoadMap';

const AboutUsPage = () => {
  return (
    <MainLayout >
      {/* <AboutHero /> */}
      <VideoContainer src={Video} />
      <Technology />
      <Partners/>
      <RoadMap/>
      <Media/>
    </MainLayout>
  );
};

export default AboutUsPage;
