import React, { useEffect, useRef, useState } from 'react';
import ErrorMessage from '../../../components/ErrorMessage';
import Slider from 'react-slick';
import { stables } from '../../../constants';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { FaCircleChevronLeft, FaCircleChevronRight } from 'react-icons/fa6';
import { useTranslation } from 'react-i18next';
import { getGallery } from '../../../services/index/gallery';
import { toast } from 'react-hot-toast';

const Media = () => {
  const slider = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [gallery, setGallery] = useState(null);

  const { t } = useTranslation();

  useEffect(() => {
    const fetchGallery = async () => {
      try {
        const { data, isLoading, isError } = await getGallery();
        setGallery(data.gallery);
        setIsLoading(isLoading);
        setIsError(isError);
      } catch (error) {
        toast.error(error.message);
        console.error(error);
      }
    };
    // Fetch studioWorks when the component mounts
    fetchGallery();
  }, []);

  const settings = {
    className: 'center',
    centerMode: true,
    infinite: true,
    centerPadding: '0px',
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    swipeToSlide: true,
    speed: 500,
    dots: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <section className="w-full bg-white min-h-screen pt-20">
      <div className="container">
        <h3 className="section-title mb-8 text-left font-MontserratArmSemiBold">{t('Media')}</h3>
        <div className="flex flex-wrap lg:justify-center md:justify-start justify-center md:gap-x-5 gap-y-5 pb-10">
          {isLoading && <div>loading</div>}
          {isError && (
            <ErrorMessage message="Couldn't fetch the Media" />
          )}
          {!isLoading && !isError && gallery && (
            <div className='w-full'>
              <Slider ref={slider} {...settings}>
                {Object.entries(gallery).map((item, key) => (
                  <div key={key.toString()}>
                    <img src={stables.UPLOAD_FOLDER_BASE_URL + item[1]} alt="" className='w-full aspect-square object-cover'/>
                  </div>
                ))}
              </Slider>
              <div className='flex w-full gap-4 justify-end items-center my-4'>
                <FaCircleChevronLeft
                  onClick={() => slider?.current?.slickPrev()}
                  className='text-3xl fill-[#d4d4d4] hover:fill-[#bfbdbd] cursor-pointer overflow-hidden rounded-full'/>
                <FaCircleChevronRight
                  onClick={() => slider?.current?.slickNext()}
                  className='text-3xl fill-[#d4d4d4] hover:fill-[#bfbdbd] cursor-pointer overflow-hidden rounded-full'/>
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default Media;
