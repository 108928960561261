import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowRight } from '../constants/icons';
import { TfiLocationPin } from 'react-icons/tfi';
import { PageSection } from './section/SectionElements';
import { useTranslation } from 'react-i18next';

const CTA = () => {
  const { t, i18n } = useTranslation();
  return (
    <PageSection>
      <div className="container">
        <h3 className="section-title mb-8 text-start">{t('Where to buy')}</h3>
        <div className="flex flex-wrap lg:justify-center md:justify-start justify-center md:gap-x-5 gap-y-5 pb-10">
          <p className='mb-16 mr-auto md:w-1/3 w-full'>{t('Discover our products at trusted retailers near you. Find convenience in your shopping experience by exploring our list of authorized sellers.')}</p>

          <Link to={`/${i18n.language}/contact#map`} className='w-full p-5 bg-gray text-dark-gray flex gap-4 items-center'>
            <TfiLocationPin className='w-12 h-12'/>
            <span>{t('Find a nearby store')}</span>
            <ArrowRight className='ml-auto'/>
          </Link>
        </div>
      </div>
    </PageSection>
  );
};

export default CTA;
