import React, { useEffect, useRef, useState } from 'react';
import { images } from '../../../constants';
import { useTranslation } from 'react-i18next';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

const imageMap = {
  0: images.EmeraldEn,
  1: images.RubyEn,
  2: images.AmethystEn
};

const reviewBubbles = {
  0: { bg: images.BubbleBlue, blur: 0, zIndex: 2 },
  1: { bg: images.BubblePurple, blur: 0, zIndex: 2 },
  2: { bg: images.BubbleDarkBlue, blur: 0, zIndex: 2 },
  3: { bg: images.BubbleBlue, blur: '2px', zIndex: 1 },
  4: { bg: images.BubblePurple, blur: 0, zIndex: 2 },
  5: { bg: images.BubbleDarkBlue, blur: '2px', zIndex: 1 },
  6: { bg: images.BubbleBlue, blur: 0, zIndex: 2 },
  7: { bg: images.BubblePurple, blur: '2px', zIndex: 1 }
};

const getRandomElement = () => {
  const keys = Object.keys(reviewBubbles);
  const randomKey = keys[Math.floor(Math.random() * keys.length)];
  return reviewBubbles[randomKey];
};

const MultiClassReview = () => {
  const sectionRef = useRef(null);
  const textContainer = useRef(null);
  const bubbleRefs = useRef([]);
  const imgRef = useRef(null);
  const { t } = useTranslation();
  const [image, setImage] = useState(images.EmeraldEn);

  useEffect(() => {
    const secRef = sectionRef.current;
    const imRef = imgRef.current;

    if(typeof window !== 'undefined') {
      gsap.registerPlugin(ScrollTrigger);
    }

    gsap.fromTo(
      imRef,
      {
        scale: window.innerWidth < 641 ? 0.5 : 1
      },
      {
        scale: window.innerWidth < 641 ? 1 : 1.2,
        duration: 1,
        ease: 'power4.inOut',
        xPercent: window.innerWidth < 641 ? 0 : -15,
        yPercent: window.innerWidth < 641 ? 18 : 25,
        scrollTrigger: {
          trigger: secRef,
          start: 'top top',
          end: 'bottom bottom',
          pin: '.pin',
          toggleActions: 'play none none reverse',
          anticipatePin: 1
        }
      });
    const headings = gsap.utils.toArray('.text-container h3');

    headings.forEach((el, i) => {
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: '.move-' + (i + 1),
          start: 'center center',
          end: '+=100%',
          scrub: 1,
          preventOverlaps: false,
          onToggle: (self) => {
            // Update the image source
            if(self.isActive && i < 3) {
              gsap.fromTo(
                imRef,
                {
                  opacity: 0.6
                },
                {
                  opacity: 1,
                  duration: 2,
                  ease: 'sine',
                  onStart: () => setImage(imageMap[i])
                }
              );
            }
          }
        }
      });
      window.innerWidth < 641
        ? tl.fromTo(el, {
          opacity: 0,
          y: '0vh'
        }, {
          opacity: 1,
          y: '-50vh',
          ease: 'sine'
        }, '-0.5')
        : tl.fromTo(el, {
          opacity: 0,
          bottom: '-100%'
        }, {
          opacity: 0.6,
          bottom: 0,
          duration: 1,
          immediateRender: false,
          ease: 'sine.in'
        }, '-=0.5');

      // : tl.fromTo(el, { opacity: 0, bottom: '-100%' }, { opacity: 1, bottom: '50%', duration: 1, immediateRender: false, ease: 'sine.in' }, '-=0.5');

      window.innerWidth > 641 && tl.fromTo(el, { bottom: '0%' }, {
        opacity: 1,
        bottom: '50%',
        duration: 2,
        immediateRender: false,
        ease: 'none'
      }, '+=0.25');

      // tl.fromTo(el, { opacity: 0, bottom: '-100%' }, { opacity: 0.6, bottom: 0, duration: 1, immediateRender: false, ease: 'sine.in' }, 0);
      // tl.fromTo(el, { bottom: '0%' }, { opacity: 1, bottom: '50%', duration: 2, immediateRender: false, ease: 'none' }, '+=0.25');
      // tl.to(el, { bottom: '100%', duration: 1, opacity: 0, ease: 'sine.out' }, '+=1');

      window.innerWidth < 641
        ? tl.to(el, {
          y: '-100vh',
          opacity: 0,
          ease: 'sine'
        })
        : tl.to(el, {
          bottom: '100%',
          duration: 1,
          opacity: 0,
          ease: 'sine.out'
        }, '+=1');
    });

    const bubbles = bubbleRefs.current.map((ref) => {
      return gsap.fromTo(ref,
        {
          scale: 1 + Math.random(),
          left: `${Math.floor(Math.random() * 70)}%`,
          top: `${Math.floor(Math.random() * 70)}%`
        },
        {
          x: () => Math.random() > 0.5 ? `+=${Math.floor(Math.random() * 350)}%` : `-=${Math.floor(Math.random() * 350)}%`,
          y: () => Math.random() > 0.5 ? `+=${Math.floor(Math.random() * 350)}%` : `-=${Math.floor(Math.random() * 350)}%`,
          duration: 1 + Math.random(),
          scale: 1 + Math.random(),
          ease: 'sine',
          scrollTrigger: {
            trigger: secRef,
            scrub: 2,
            end: 'bottom bottom'
          }
        });
    });

    // Pause all animations when the component unmounts
    return () => {
      bubbles.forEach((bubble) => bubble.pause());
      gsap.killTweensOf(secRef); // Clean up animations on unmount
      gsap.killTweensOf(imRef); // Clean up animations on unmount
    };
  }, []);

  return (
    <section ref={sectionRef} className="w-full min-h-screen pt-20 relative bg-fixed bg-cover bg-no-repeat bg-center overflow-hidden" style={{ backgroundImage: `url(${images.MultiClassReviewBg})` }}>

      <div className='flex flex-row relative z-20 min-h-screen'>
        <div className='pin flex md:flex-row flex-col min-h-screen absolute w-full top-0 left-0 z-20'>
          <div className='img-container md:w-1/4 w-full md:order-first order-last flex justify-start items-center md:max-h-[90vh] max-h-[60%] z-20'>
            <img className='mx-auto' ref={imgRef} src={image} alt=""/>
          </div>
          <div ref={textContainer} className='relative text-container md:w-3/4 w-full md:h-auto h-full md:order-last order-first flex flex-col justify-center items-center'>
            <h3 className="z-20 max-w-[70%] md:px-32 px-12 drop-shadow-md absolute -bottom-full text-[min(5vw,3rem)] text-center felx items-center text-white font-MontserratArmSemiBold">{t('Explore the captivating brightness that our capsules bring to every puff.')}</h3>
            <h3 className="z-20 max-w-[70%] md:px-32 px-12 drop-shadow-md absolute -bottom-full text-[min(5vw,3rem)] text-center felx items-center text-white font-MontserratArmSemiBold">{t('Each capsule release adds an exciting twist to your smoke, tailored to your preference.')}</h3>
            <h3 className="z-20 max-w-[70%] md:px-32 px-12 drop-shadow-md absolute -bottom-full text-[min(5vw,3rem)] text-center felx items-center text-white font-MontserratArmSemiBold">{t('Revel in the dynamic interplay of taste and aroma with Multi Class.\n')}</h3>
            <h3 className="z-20 max-w-[70%] md:px-32 px-12 drop-shadow-md absolute -bottom-full text-[min(5vw,3rem)] text-center felx items-center text-white font-MontserratArmSemiBold"/>
            <div className='absolute w-full md:h-full h-screen left-0 top-0'>
              {Array.from({ length: 10 }).map((_, index) => {
                const bubble = getRandomElement();
                return (
                  <div
                    key={index}
                    ref={(el) => (bubbleRefs.current[index] = el)}
                    className={'bubble absolute w-12 h-12 bg-contain bg-no-repeat bg-center'}
                    style={{
                      backgroundImage: `url(${bubble.bg})`,
                      filter: `blur(${bubble.blur})`,
                      zIndex: bubble.zIndex
                    }}
                  />
                );
              }
              )}
            </div>
          </div>
        </div>
        <div className='relative'>
          <section className="panel move-1 bg-gray min-h-screen"></section>
          <section className="panel move-2 bg-[blue] min-h-screen"></section>
          <section className="panel move-3 bg-[red] min-h-screen"></section>
          <section className="panel move-4 bg-[green] min-h-screen"></section>
        </div>
      </div>

    </section>
  );
};

export default MultiClassReview;
