import { Link, useNavigate } from 'react-router-dom';
import { useWindowSize } from '@uidotdev/usehooks';

import { images } from '../../../../constants';
import React, { useEffect, useState } from 'react';
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai';
import { NavButton, NavItem } from './NavItem';
import NavItemCollapse from './NavItemCollapse';
import { useTranslation } from 'react-i18next';
import { logout } from '../../../../store/actions/user';
import { useDispatch } from 'react-redux';
import { FiLogOut, FiSettings } from 'react-icons/fi';
import { LiaLanguageSolid } from 'react-icons/lia';
import { RxDashboard } from 'react-icons/rx';
import { PiShoppingCartSimpleBold } from 'react-icons/pi';
import { RiContactsBookLine } from 'react-icons/ri';
import { GrGallery } from 'react-icons/gr';

const MENU_ITEMS = [
  // {
  //   title: 'Dashboard',
  //   link: '/admin',
  //   icon: <AiOutlineDashboard className="text-xl" />,
  //   name: 'dashboard',
  //   type: 'link'
  // },
  // {
  //   title: 'Catalogs',
  //   content: [
  //     { title: 'Brands', link: '/admin/brands/manage' },
  //     { title: 'Products', link: '/admin/products/manage' }
  //   ],
  //   icon: <RxDashboard className="text-xl" />,
  //   name: 'catalog',
  //   type: 'collapse'
  // },
  // {
  //   title: 'Sections',
  //   content: [
  //     { title: 'Studio Works', link: '/admin/studio-works' },
  //     { title: 'About Us', link: '/admin/about' }
  //   ],
  //   icon: <RxDashboard className="text-xl" />,
  //   name: 'sections',
  //   type: 'collapse'
  // },
  {
    title: 'Brands',
    link: '/admin/brands/manage',
    icon: <RxDashboard className="text-xl" />,
    name: 'brands',
    type: 'link'
  },
  {
    title: 'Products',
    link: '/admin/products/manage',
    icon: <PiShoppingCartSimpleBold className="text-xl" />,
    name: 'products',
    type: 'link'
  },
  {
    title: 'Gallery',
    link: '/admin/gallery',
    icon: <GrGallery className="text-xl" />,
    name: 'gallery',
    type: 'link'
  },
  {
    title: 'Contacts',
    link: '/admin/contacts',
    icon: <RiContactsBookLine className="text-xl" />,
    name: 'contacts',
    type: 'link'
  },
  {
    title: 'Translations',
    link: '/admin/translations',
    icon: <LiaLanguageSolid className="text-xl" />,
    name: 'translations',
    type: 'link'
  },
  {
    title: 'User',
    link: '/admin/usersettings',
    icon: <FiSettings className="text-xl" />,
    name: 'user',
    type: 'link'
  }
  // {
  //   title: 'Settings',
  //   content: [
  //     { title: 'webSite', link: '/admin/websettings' },
  //     { title: 'User', link: '/admin/usersettings' }
  //   ],
  //   icon: <FiSettings className="text-xl" />,
  //   name: 'settings',
  //   type: 'collapse'
  // }
];

const Header = () => {
  const [isMenuActive, setIsMenuActive] = useState(false);
  const [activeNavName, setActiveNavName] = useState('dashboard');
  const dispatch = useDispatch();
  const windowSize = useWindowSize();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const logoutHandler = () => {
    dispatch(logout());
    navigate('/');
  };

  const toggleMenuHandler = () => {
    setIsMenuActive((prevState) => !prevState);
  };

  useEffect(() => {
    if(windowSize.width < 1024) {
      setIsMenuActive(false);
    } else {
      setIsMenuActive(true);
    }
  }, [windowSize.width]);

  return (
    <header className="flex h-16 w-full items-center justify-between p-2 z-900 lg:h-full lg:max-w-[300px] lg:flex-col lg:items-start lg:justify-start lg:p-0">
      {/* logo */}
      <Link to="/" className='lg:h-auto h-full'>
        <img src={images.Logo} alt="logo" className="w-16 lg:hidden brightness-[0.2] h-full object-contain" />
      </Link>
      {/* menu burger icon */}
      <div className="cursor-pointer lg:hidden">
        {isMenuActive
          ? (
            <AiOutlineClose className="w-6 h-6" onClick={toggleMenuHandler} />
          )
          : (
            <AiOutlineMenu className="w-6 h-6" onClick={toggleMenuHandler} />
          )}
      </div>
      {/* sidebar container */}
      {isMenuActive && (
        <div className="fixed inset-0 lg:static lg:h-full lg:w-full">
          {/* underlay */}
          <div
            className="fixed inset-0 bg-black opacity-50 lg:hidden"
            onClick={toggleMenuHandler}
          />
          {/* sidebar */}
          <div className="fixed top-0 bottom-0 left-0 z-50 w-3/4 overflow-y-auto bg-white p-4 lg:static lg:h-full lg:w-full lg:p-6">
            <Link to="/">
              <img src={images.Logo} alt="logo" className="w-16 brightness-[0.2]" />
            </Link>
            <h4 className="mt-10 font-MontserratArmSemiBold text-[#C7C7C7]">MAIN MENU</h4>
            {/* menu items */}
            <div className="mt-6 flex flex-col gap-y-[0.563rem]">
              {MENU_ITEMS.map((item) =>
                item.type === 'link'
                  ? (
                    <NavItem
                      key={item.title}
                      title={t(item.title)}
                      link={item.link}
                      icon={item.icon}
                      name={item.name}
                      activeNavName={activeNavName}
                      setActiveNavName={setActiveNavName}
                    />
                  )
                  : (
                    <NavItemCollapse
                      key={item.title}
                      title={item.title}
                      content={item.content}
                      icon={item.icon}
                      name={item.name}
                      activeNavName={activeNavName}
                      setActiveNavName={setActiveNavName}
                    />
                  )
              )}
              <NavButton
                title={t('LogOut')}
                onClick={logoutHandler}
                icon={<FiLogOut className="text-xl" />}
                name={'logout'}
                activeNavName={activeNavName}
                setActiveNavName={setActiveNavName}
              />
            </div>
          </div>
        </div>
      )}
    </header>
  );
};

export default Header;
