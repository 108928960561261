import React, { useEffect, useState } from 'react';
import Footer from './Footer';
import Header from './Header';
import { Sidebar } from './sidebar/Sidebar';
import { navItemsInfo } from './PageData';
import CTA from './CTA';

const MainLayout = ({ cta = true, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen((prev) => !prev);
  };

  useEffect(() => {
    isOpen ? document.body.style.overflowY = 'hidden' : document.body.style.overflowY = 'auto';
  }, [isOpen]);

  return (
    <div>
      <Sidebar isOpen={isOpen} toggle={toggle}/>
      <Header toggle={toggle} navItems={navItemsInfo}/>
      {children}
      {cta && <CTA/>}
      <Footer navItems={navItemsInfo}/>
    </div>
  );
};

export default MainLayout;
