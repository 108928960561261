import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ArticleDetailSkeleton from '../../components/ArticleDetailSkeleton';
import ErrorMessage from '../../../../components/ErrorMessage';
import { images, stables } from '../../../../constants';
import { toast } from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { getSingleBrand, updateBrand } from '../../../../services/index/brands';
import { NavBtn } from '../../../../components/navBar/NavbarElements';
import { locales } from '../../../../components/PageData';
import { useTranslation } from 'react-i18next';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { FaCircleXmark } from 'react-icons/fa6';

const ImageUpload = ({ image, initialImage, handleFileChange, handleDeleteImage, id, label, alt }) => (
  <label htmlFor={id} className="md:w-1/2 w-full cursor-pointer px-4">
    <span className="text-[#5a7184] font-Montserrat block my-4">{label}</span>
    <div className="w-full md:m-0 mx-auto md bg-white rounded-lg overflow-hidden items-center">
      <div className="px-4 py-6 h-80 flex justify-center items-center">
        <label id={id} className="w-full relative h-full p-6 m-4 bg-gray-100 border-dashed border-2 border-gray-400 rounded-lg flex flex-col items-center justify-center text-center cursor-pointer">
          <input
            id={`upload-${id}`}
            type="file"
            className="hidden"
            onChange={handleFileChange}
            accept="image/*" />
          {image
            ? (
              <img src={URL.createObjectURL(image)} className="max-h-full mx-auto bg-contain bg-center" alt="" style={{ backgroundImage: `url(${images.TransparentBg})` }} />

            )
            : initialImage
              ? (
                <img src={initialImage} className="max-h-full mx-auto bg-contain bg-center" alt="" style={{ backgroundImage: `url(${images.TransparentBg})` }} />

              )
              : (<>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                  stroke="currentColor" className="w-8 h-8 text-gray-700 mx-auto mb-4">
                  <path strokeLinecap="round" strokeLinejoin="round"
                    d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5m-13.5-9L12 3m0 0l4.5 4.5M12 3v13.5"/>
                </svg>
                <h5 className="mb-2 text-xl font-MontserratArmSemiBold tracking-tight text-gray-700">Upload picture</h5>
              </>
              )}
          {(image || initialImage) && <FaCircleXmark
            onClick={handleDeleteImage}
            className="bg-white text-red-500 text-3xl absolute -top-4 -right-4"/>}
        </label>
      </div>
    </div>
  </label>
);

const EditBrand = () => {
  const { slug } = useParams();
  const userState = useSelector((state) => state.user);
  const [initialImage, setInitialImage] = useState(null);
  const [initialLogo, setInitialLogo] = useState(null);
  const [image, setImage] = useState(null);
  const [logo, setLogo] = useState(null);
  const [profileDropdown, setProfileDropdown] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [isImageRemoved, setIsImageRemoved] = useState(false);
  const [isLogoRemoved, setIsLogoRemoved] = useState(false);
  const [brandData, setBrandData] = useState(null);
  const [currentLng, setCurrentLng] = useState(Object.keys(locales)[0]);
  const [formData, setFormData] = useState({});

  const { t, i18n } = useTranslation();

  useEffect(() => {
    const fetchBrands = async () => {
      try {
        const { data, isLoading, isError } = await getSingleBrand({ slug });
        setBrandData(data);
        setFormData({ names: data.names, descriptions: data.descriptions });
        setIsLoading(isLoading);
        setIsError(isError);
      } catch (error) {
        toast.error(error.message);
        console.error(error);
      }
    };
    fetchBrands();
  }, [slug]);

  useEffect(() => {
    setCurrentLng(i18n.language);
  }, [i18n.language]);

  const changeLanguage = async (data) => {
    const { name, description, locale } = data;
    setFormData({ names: { ...formData.names, [currentLng]: name }, descriptions: { ...formData.descriptions, [currentLng]: description } });
    setCurrentLng(locale);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm({
    mode: 'onChange'
  });

  useEffect(() => {
    if(brandData) {
      setInitialImage(brandData?.image ? stables.UPLOAD_FOLDER_BASE_URL + brandData?.image : null);
      setInitialLogo(brandData?.logo ? stables.UPLOAD_FOLDER_BASE_URL + brandData?.logo : null);
      brandData.names &&
      reset({ name: formData.names[currentLng] || brandData.names[currentLng], description: formData.descriptions[currentLng] || brandData?.descriptions[currentLng] });
    }
  }, [reset, brandData, currentLng, formData]);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImage(file);
  };
  const handleLogoChange = (e) => {
    const file = e.target.files[0];
    setLogo(file);
  };

  const handleUpdateBrand = async (data) => {
    const updatedData = new FormData();

    // Check if there's a new image, if so, append it
    if(image) {
      updatedData.append('brandImage', image);
    }

    if(logo) {
      updatedData.append('brandLogo', logo);
    }
    const { name, description } = data;
    const arr = { names: { ...formData.names, [currentLng]: name }, descriptions: { ...formData.descriptions, [currentLng]: description }, isImageRemoved: image === null && initialImage === null && isImageRemoved, isLogoRemoved: logo === null && initialLogo === null && isLogoRemoved };

    updatedData.append('document', JSON.stringify({ data: arr }));

    try {
      await updateBrand({ updatedData, slug, token: userState.userInfo.token });

      return toast.success('Brand is updated');
    } catch (error) {
      toast.error(error.message);
      console.error(error);
    }
  };

  const handleDeleteImage = () => {
    if(window.confirm('Do you want to delete your Brand picture?')) {
      setImage(null);
      setInitialImage(null);
      setIsImageRemoved(true);
    }
  };

  const handleDeleteLogo = () => {
    if(window.confirm('Do you want to delete your Brand Image?')) {
      setLogo(null);
      setInitialLogo(null);
      setIsLogoRemoved(true);
    }
  };

  return (
    <>
      {isLoading
        ? (
          <ArticleDetailSkeleton />
        )
        : isError
          ? (
            <ErrorMessage message="Couldn't fetch the brand detail" />
          )
          : (<section className="container mx-auto max-w-5xl flex flex-col px-5 py-5 lg:flex-row lg:gap-x-5 lg:items-start">
            <article className="flex-1">
              <div className='flex flex-wrap'>
                <ImageUpload
                  image={logo}
                  id={'brandLogo'}
                  label={'Brand Logo'}
                  initialImage={initialLogo}
                  alt={brandData?.name}
                  handleFileChange={handleLogoChange}
                  handleDeleteImage={handleDeleteLogo}
                />
                <ImageUpload
                  image={image}
                  id={'brandImage'}
                  label={'Brand Image'}
                  initialImage={initialImage}
                  alt={brandData?.name}
                  handleFileChange={handleImageChange}
                  handleDeleteImage={handleDeleteImage}
                />
              </div>
              <div className='flex justify-end w-full my-4'>
                <div className="text-white items-center gap-y-5 lg:text-black flex flex-col lg:flex-row gap-x-2 font-Montserrat">
                  <div className="relative group">
                    <div className="flex flex-col items-center">
                      <NavBtn onClick={() => setProfileDropdown(!profileDropdown)}>
                        <span>{locales[currentLng].name}</span>
                        <MdKeyboardArrowDown/>
                      </NavBtn>
                      <div
                        className={`${
                          profileDropdown ? 'block' : 'hidden'
                        } z-20 transition-all duration-500 pt-4 absolute bottom-0 right-0 transform translate-y-full group-hover:block w-max`}
                      >
                        <ul
                          className="bg-dark-light text-center flex flex-col shadow-lg rounded-lg overflow-hidden w-full">
                          {Object.keys(locales).map((locale) => (
                            <NavBtn
                              key={locale}
                              onClick={handleSubmit((data) => changeLanguage({ ...data, locale }))}
                              className={`hover:bg-dark-hard hover:text-white px-4 py-2 text-black text-center cursor-pointer uppercase group ${currentLng === locale ? 'font-MontserratArmSemiBold' : 'font-normal'}`}>
                              <p>{locales[locale].name}</p>
                            </NavBtn>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col mb-6 w-full">
                <label
                  htmlFor="name"
                  className="text-[#5a7184] font-Montserrat block"
                >
                  Name
                </label>
                <input
                  type="text"
                  id="name"
                  {...register('name', {
                    minLength: {
                      value: 1,
                      message: 'Name length must be at least 1 character'
                    },
                    required: {
                      value: false,
                      message: 'Name is required'
                    }
                  })}
                  placeholder="Enter Name"
                  className={`placeholder:text-[#959ead] text-black mt-3 rounded-lg px-5 py-4 font-Montserrat block outline-none border ${
                    errors.name ? 'border-red-500' : 'border-[#c3cad9]'
                  }`}
                />
                {errors.name?.message && (
                  <p className="text-red-500 text-xs mt-1">
                    {errors.name?.message}
                  </p>
                )}
              </div>
              <div className="flex flex-col mb-6 w-full">
                <label
                  htmlFor="description"
                  className="text-[#5a7184] font-Montserrat block"
                >
                  Description
                </label>
                <textarea
                  id="description"
                  rows='10'
                  {...register('description', {
                    minLength: {
                      value: 1,
                      message: 'Description length must be at least 1 character'
                    },
                    required: {
                      value: false,
                      message: 'Description is required'
                    }
                  })}
                  placeholder="Enter Description"
                  className={`placeholder:text-[#959ead] text-black mt-3 rounded-lg px-5 py-4 font-Montserrat block outline-none border resize-none ${
                    errors.description ? 'border-red-500' : 'border-[#c3cad9]'
                  }`}
                />
                {errors.description?.message && (
                  <p className="text-red-500 text-xs mt-1">
                    {errors.description?.message}
                  </p>
                )}
              </div>
              <button
                disabled={isLoading}
                type="button"
                onClick={handleSubmit(handleUpdateBrand)}
                className="w-full bg-green-500 text-white hover:bg-green-500/90 font-Montserrat rounded-lg px-4 py-2 disabled:cursor-not-allowed disabled:opacity-70"
              >
                {t('Save')}
              </button>
            </article>
          </section>
          )
      }
    </>
  );
};

export default EditBrand;
