import React, { useState } from 'react';
import { toast } from 'react-hot-toast';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { stables } from '../../../constants';
import { updateGalleryPicture } from '../../../services/index/gallery';

const WorkPhoto = ({ gallery, slug }) => {
  const queryClient = useQueryClient();
  const userState = useSelector((state) => state.user);
  // eslint-disable-next-line no-unused-vars
  const [photo, setPhoto] = useState(null);

  const { mutate, isLoading } = useMutation({
    mutationFn: ({ token, formData, slug }) => {
      return updateGalleryPicture({
        token,
        formData,
        slug
      });
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(['gallery' + slug]);
      toast.success(`Studio Work ${slug} Photo is updated`);
    },
    onError: (error) => {
      toast.error(error.message);
      console.log(error);
    }
  });
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setPhoto(file);

    const formData = new FormData();
    formData.append('gallery' + slug, file);

    mutate({ token: userState.userInfo.token, slug, formData });
  };

  return (
    <div className="mx-auto xl:w-[calc(33.3333%-1rem)] lg:w-[calc(50%-1rem)] md:w-[calc(50%-1rem)] w-full md:m-0 rounded-lg shadow-md overflow-hidden items-center">
      <div className="bg-white px-4 py-6 h-80">
        <label id="image-preview" className="p-6 mb-4 bg-gray-100 border-dashed border-2 border-gray-400 rounded-lg flex flex-col items-center justify-center text-center cursor-pointer">
          <input
            id="upload"
            type="file"
            className="hidden"
            onChange={handleFileChange}
            accept="image/*" />
          {isLoading
            ? (<div>Loading...</div>)
            : <>
              {photo
                ? (
                  <img src={URL.createObjectURL(photo)} className="max-h-48 rounded-lg mx-auto" alt="Studio Work Image"/>
                )
                : gallery
                  ? (
                    <img src={stables.UPLOAD_FOLDER_BASE_URL + gallery} className="max-h-48 rounded-lg mx-auto" alt="Studio Work Image"/>

                  )
                  : (<>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                      stroke="currentColor" className="w-8 h-8 text-gray-700 mx-auto mb-4">
                      <path strokeLinecap="round" strokeLinejoin="round"
                        d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5m-13.5-9L12 3m0 0l4.5 4.5M12 3v13.5"/>
                    </svg>
                    <h5 className="mb-2 text-xl font-bold tracking-tight text-gray-700">Upload picture</h5>
                  </>
                  )}
            </>
          }
        </label>
        <div className="flex items-center justify-center">
          <div className="w-full">
            <div className="w-full text-green-500 font-medium rounded-lg text-sm px-5 py-2.5 flex items-center justify-center mr-2 mb-2">
              <span className="text-center ml-2">Click on picture to change</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkPhoto;
