import Logo from '../assets/LogoColored.png';
import ColoredLogo from '../assets/logoColred.png';
import LogoDark from '../assets/LogoDark.png';
import FooterLogo1 from '../assets/footerLogo1.png';
import Banner from '../assets/banner.webp';
import Banner2 from '../assets/banner2.webp';
import AboutBanner from '../assets/about-banner.png';
import MultiClassReviewBg from '../assets/reviewBg.png';
import AdultBg from '../assets/adultBg.png';
import Cigarette from '../assets/cigarette.png';
import BrandMulti from '../assets/brandMulti.png';
import BrandMultiBlack from '../assets/brandMultiB_W.png';
import BubbleBlue from '../assets/bubbleBlue.png';
import BubbleDarkBlue from '../assets/bubbleDarkBlue.png';
import BubblePurple from '../assets/bubblePurple.png';
import HatisBlackSeaEn from '../assets/3dEn/Hatis_KS_BlackEn.webp';
import HatisBlueSkyEn from '../assets/3dEn/Hatis_KS_BlueEn.webp';
import HatisWhiteMoonEn from '../assets/3dEn/Hatis_KS_WhiteEn.webp';
import KarsDiamondValleyEn from '../assets/3dEn/Kars_KS_DiamondEn.webp';
import KarsDreamValleyEn from '../assets/3dEn/Kars_KS_DreamEn.webp';
import KarsMysticValleyEn from '../assets/3dEn/Kars_KS_MysticEn.webp';
import MultiTabakSouthEn from '../assets/3dEn/MultiTabak_KS_BlackEn.webp';
import MultiTabakNorthEn from '../assets/3dEn/MultiTabak_KS_BlueEn.webp';
import MultiTabakArcticEn from '../assets/3dEn/MultiTabak_KS_WhiteEn.webp';
import EmeraldEn from '../assets/3dEn/MultiClass_NS_Emerald_FrontEn.webp';
import RubyEn from '../assets/3dEn/MultiClass_NS_Ruby_FrontEn.webp';
import AmethystEn from '../assets/3dEn/MultiClass_QS_Amethyst_frontEn.webp';
import MultiClassTurmalineEn from '../assets/3dEn/MultiClass_QS_Turmaline_FrontEn.webp';
import MultiClassSSAmethystEn from '../assets/3dEn/MultiClass_SS_Amethyst_frontEn.webp';
import AquamarineEn from '../assets/3dEn/MultiClass_SS_Aquamarine_frontEn.webp';
import Tech1 from '../assets/tech1.png';
import Tech2 from '../assets/tech2.png';
import Tech3 from '../assets/tech3.png';
import Beta from '../assets/partners/Beta.png';
import Clark from '../assets/partners/clark.png';
import Donfiss from '../assets/partners/donfiss.png';
import Hormann from '../assets/partners/hormann.png';
import Hikvision from '../assets/partners/hikvision.png';
import Kaeser from '../assets/partners/kaeser.png';
import Pedroll from '../assets/partners/pedroll.png';
import Riedel from '../assets/partners/riedel.png';
import Skf from '../assets/partners/skf.png';
import Voith from '../assets/partners/voith.png';
import TransparentBg from '../assets/trsnsparent-bg.jpg';

import Paper1 from '../assets/personalize/paper.png';
import Paper2 from '../assets/personalize/paper2.png';
import Paper3 from '../assets/personalize/paper3.png';
import Filter1 from '../assets/personalize/filter.png';
import Filter2 from '../assets/personalize/filter2.png';
import Filter3 from '../assets/personalize/filter3.png';
import Fl1 from '../assets/personalize/fl.png';
import Fl2 from '../assets/personalize/fl2.png';
import Fl3 from '../assets/personalize/fl3.png';
import Fl4 from '../assets/personalize/fl4.png';
import Fl5 from '../assets/personalize/fl5.png';
import Leaf1 from '../assets/personalize/leaf1.png';
import Leaf2 from '../assets/personalize/leaf2.png';
import Leaf3 from '../assets/personalize/leaf3.png';
import Pack1 from '../assets/personalize/pack.png';
import Pack2 from '../assets/personalize/pack2.png';
import Pack3 from '../assets/personalize/pack3.png';
import Pack4 from '../assets/personalize/pack4.png';
import Pack5 from '../assets/personalize/pack5.png';

const samplePostImage = '/images/noImage.jpg';
const noImage = '/images/noImage.jpg';
const userImage = '/images/user.png';

const images = {
  Logo,
  LogoDark,
  ColoredLogo,
  FooterLogo1,
  Banner,
  Banner2,
  AboutBanner,
  MultiClassReviewBg,
  AdultBg,
  Cigarette,
  // ProductClass,
  BrandMulti,
  BrandMultiBlack,
  BubbleBlue,
  BubbleDarkBlue,
  BubblePurple,
  samplePostImage,
  TransparentBg,
  noImage,
  userImage,
  Tech1,
  Tech2,
  Tech3,
  // 3d
  HatisBlackSeaEn,
  HatisBlueSkyEn,
  HatisWhiteMoonEn,
  KarsDreamValleyEn,
  KarsMysticValleyEn,
  KarsDiamondValleyEn,
  MultiTabakSouthEn,
  MultiTabakNorthEn,
  MultiTabakArcticEn,
  EmeraldEn,
  RubyEn,
  AmethystEn,
  MultiClassTurmalineEn,
  MultiClassSSAmethystEn,
  AquamarineEn,
  // partners
  Beta,
  Clark,
  Donfiss,
  Hormann,
  Hikvision,
  Kaeser,
  Pedroll,
  Riedel,
  Skf,
  Voith,
  // Personalize
  Paper1,
  Paper2,
  Paper3,
  Filter1,
  Filter2,
  Filter3,
  Fl1,
  Fl2,
  Fl3,
  Fl4,
  Fl5,
  Leaf1,
  Leaf2,
  Leaf3,
  Pack1,
  Pack2,
  Pack3,
  Pack4,
  Pack5
};

export default images;
