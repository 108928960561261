import React, { useEffect, useState } from 'react';
import ErrorMessage from '../../../components/ErrorMessage';
import { stables } from '../../../constants';
import { motion, LayoutGroup, AnimatePresence } from 'framer-motion';
import { getGallery } from '../../../services/index/gallery';
import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

// const Images = [
//   { image: images.Media1 },
//   { image: images.Media2 },
//   { image: images.Media3 },
//   { image: images.Media4 },
//   { image: images.Media5 },
//   { image: images.Media6 }
// ];
const Gallery = ({ items, setIndex }) => {
  return (
    <ul className="m-0 p-0 grid gap-4 md:grid-cols-3 grid-cols-2 grid-rows-2 w-full">
      {Object.values(items).map((item, i) => (
        <motion.li
          key={i.toString()}
          onClick={() => setIndex(i)}
          layoutId={i.toString()}
          className='w-full h-full'
        >
          <img
            alt=''
            src={stables.UPLOAD_FOLDER_BASE_URL + item.image}
            className="cursor-pointer object-cover w-full h-full aspect-4/3"
          />
        </motion.li>
      ))}
    </ul>
  );
};
const SingleImage = ({ item, onClick }) => {
  return (
    <div className="single-image-container absolute top-0 left-0 bottom-0 right-0 flex justify-center items-center pointer-events-none" onClick={onClick}>
      <motion.div
        layoutId={item.index}
        className="single-image w-full h-full max-w-[90%] max-h-[90vh] bg-center bg-no-repeat bg-cover"
      >
        <img src={stables.UPLOAD_FOLDER_BASE_URL + item.image} alt="" className='w-full h-full object-cover'/>
      </motion.div>
    </div>
  );
};

const Media = () => {
  const [index, setIndex] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [gallery, setGallery] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    const fetchGallery = async () => {
      try {
        const { data, isLoading, isError } = await getGallery();
        const updatedGallery = {};
        Object.entries(data.gallery).forEach(([key, value], index) => {
          updatedGallery[key] = { image: value, index };
        });
        setGallery(updatedGallery);
        setIsLoading(isLoading);
        setIsError(isError);
      } catch (error) {
        toast.error(error.message);
        console.error(error);
      }
    };
    // Fetch studioWorks when the component mounts
    fetchGallery();
  }, []);

  return (
    <section className="relative w-full bg-white min-h-screen pt-20 z-5 flex items-center">
      <div className="flex flex-wrap lg:justify-center md:justify-start justify-center md:gap-x-5 gap-y-5 pb-10">
        <div className="container h-full flex flex-col justify-center">
          <h3 className="section-title mb-24 text-left font-MontserratArmSemiBold">{t('Media')}</h3>
        </div>
        {isLoading && <div>loading</div>}
        {isError && (
          <ErrorMessage message="Couldn't fetch the Media" />
        )}
        {!isLoading && !isError && gallery && (
          <div className='w-full relative'>
            <LayoutGroup>
              <Gallery items={gallery} setIndex={setIndex} />
              <AnimatePresence>
                {index !== false && (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 0.6 }}
                    exit={{ opacity: 0 }}
                    key="overlay"
                    className="bg-white opacity-20 fixed top-0 left-0 bottom-0 right-0"
                    onClick={() => setIndex(false)}
                  />
                )}

                {index !== false && (
                  <SingleImage
                    key="image"
                    index={index}
                    item={Object.values(gallery)[index]}
                    setIndex={setIndex}
                    onClick={() => setIndex(false)}
                  />
                )}
              </AnimatePresence>
            </LayoutGroup>

          </div>
        )}
      </div>
    </section>
  );
};

export default Media;
