import axios from 'axios';

export const getAllBrands = async (searchKeyword = '', page, limit) => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_HOST}/api/brands?searchKeyword=${searchKeyword}&page=${page}&limit=${limit}`
    );
    return { data };
  } catch (error) {
    if(error.response && error.response.data.message) { throw new Error(error.response.data.message); }
    throw new Error(error.message);
  }
};

export const createBrand = async ({ newData, token }) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`
      }
    };
    return await axios.put(`${process.env.REACT_APP_HOST}/api/brands/create`, newData, config);
  } catch (error) {
    if(error.response && error.response.data.message) { throw new Error(error.response.data.message); }
    throw new Error(error.message);
  }
};

export const getSingleBrand = async ({ slug }) => {
  try {
    const { data } = await axios.get(`${process.env.REACT_APP_HOST}/api/brands/${slug}`);
    return { data };
  } catch (error) {
    if(error.response && error.response.data.message) { throw new Error(error.response.data.message); }
    throw new Error(error.message);
  }
};

export const deleteBrand = async ({ slug, token }) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };

    const { data } = await axios.delete(`${process.env.REACT_APP_HOST}/api/brands/${slug}`, config);
    return data;
  } catch (error) {
    if(error.response && error.response.data.message) { throw new Error(error.response.data.message); }
    throw new Error(error.message);
  }
};

export const updateBrand = async ({ updatedData, slug, token }) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    const { data } = await axios.put(`${process.env.REACT_APP_HOST}/api/brands/${slug}`, updatedData, config);
    return data;
  } catch (error) {
    if(error.response && error.response.data.message) { throw new Error(error.response.data.message); }
    throw new Error(error.message);
  }
};
