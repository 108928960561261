export const navItemsInfo = {
  home: { name: 'Home', type: 'link', href: '/' },
  // product: { name: 'Products', type: 'link', href: '/products' },
  products: {
    name: 'Products',
    type: 'dropdown',
    items: [
      { title: 'Multi Tabak', href: '/brand/Multi_Tabak' },
      { title: 'Hatis', href: '/brand/Hatis' },
      { title: 'Kars', href: '/brand/Kars' },
      { title: 'Multi Class', href: '/brand/Multi_Class' },
      { title: 'All Products', href: '/products' }
    ]
  },
  personalize: { name: 'Personalize', type: 'link', href: '/personalize' },
  aboutUs: { name: 'About Us', type: 'link', href: '/aboutUs' },
  contact: { name: 'Contact', type: 'link', href: '/contact' }
};

export const locales = {
  en: { title: 'Eng', name: 'English' },
  hy: { title: 'Հայ', name: 'Հայերեն' },
  ru: { title: 'Рус', name: 'Русский' }
};
