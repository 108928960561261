import React, { useEffect, useRef, useState } from 'react';
import BrandCard from '../../../components/BrandCard';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { getAllBrands } from '../../../services/index/brands';
import { toast } from 'react-hot-toast';
import ArticleDetailSkeleton from '../../admin/components/ArticleDetailSkeleton';
import ErrorMessage from '../../../components/ErrorMessage';

const Brands = () => {
  const sectionRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [brands, setBrands] = useState(null);

  useEffect(() => {
    const fetchBrands = async () => {
      try {
        const { data, isLoading, isError } = await getAllBrands();
        setBrands(data.result);
        setIsLoading(isLoading);
        setIsError(isError);
      } catch (error) {
        toast.error(error.message);
        console.error(error);
      }
    };
    // Fetch studioWorks when the component mounts
    fetchBrands();
  }, []);

  useEffect(() => {
    const secRef = sectionRef.current;
    const tl = gsap.timeline();

    if(typeof window !== 'undefined') {
      gsap.registerPlugin(ScrollTrigger);
    }

    brands && !isLoading && tl.fromTo(
      secRef.querySelectorAll('.item'),
      { opacity: 0, y: +100 },
      {
        opacity: 1,
        y: 0,
        stagger: 1,
        duration: 2,
        ease: 'back.inOut',
        scrollTrigger: {
          trigger: secRef,
          start: 'top center', // Adjust the start trigger as needed
          end: 'bottom bottom', // Adjust the end trigger as needed
          toggleActions: 'play none none reverse',
          scrub: 2
        }
      }
    );

    return () => {
      gsap.killTweensOf(secRef); // Clean up animations on unmount
    };
  }, [brands, isLoading]);

  return (
    <section ref={sectionRef} className='w-full bg-[#0F0F0F] p-0 min-h-screen relative'>
      { isLoading && <ArticleDetailSkeleton /> }
      {isError && (
        <div className='absolute w-full left-1/2 top-1/2 -translate-x-1/2'>
          <ErrorMessage message="Couldn't fetch the Brands section" />
        </div>
      )}

      {!isLoading && !isError && brands &&
        <div className="container min-h-[260vh] relative">
          <div className='sticky top-0 z-10'>
            <div
              className="min-h-screen pt-20 items-stretch w-full flex flex-wrap lg:justify-center md:justify-start justify-center gap-y-8 overflow-hidden">
              {brands && brands.map((brand, key) => (
                <BrandCard
                  key={key.toString()}
                  item={brand}
                  className="item w-full opacity-0 md:w-1/2 lg:w-1/4" />
              ))}
            </div>
          </div>
        </div>
      }
    </section>
  );
};

export default Brands;
