import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { FaTimes } from 'react-icons/fa';
import { MdKeyboardArrowDown } from 'react-icons/md';

export const SidebarContainer = ({ children, isOpen }) => {
  const opacity = isOpen ? 'opacity-100' : 'opacity-0 hidden';
  const top = isOpen ? 'top-0' : '-top-50';
  const zIndex = isOpen ? 'z-last' : 'z-0';

  return (
    <aside
      className={`fixed ${zIndex} w-full h-full px-4 bg-dark-light flex top-0 left-0 ${opacity} ${top}`}
    >
      {children}
    </aside>
  );
};

export const CloseIcon = () => <FaTimes color="black" className="p-2 border-2 rounded"/>;

export const Icon = ({ children, ...rest }) => (
  <div
    {...rest}
    className="absolute top-5 right-4 bg-transparent text-3xl cursor-pointer outline-none"
  >
    {children}
  </div>
);

export const SidebarWrapper = ({ children }) => (
  <div className="sidebar-wrapper">{children}</div>
);

export const SidebarMenu = ({ children }) => (
  <ul className="block w-100 border-t-2 border-black text-center">
    {children}
  </ul>
);

export const SidebarLink = ({ navLinkItem, ...rest }) => {
  const isDropDown = (navLinkItem.type && navLinkItem.type === 'dropdown');
  const [dropdown, setDropdown] = useState(false);

  const toggleDropdownHandler = () => {
    setDropdown((curState) => {
      return !curState;
    });
  };

  return (
    <>
      {!isDropDown
        ? (
          <Link
            {...rest}
            to={navLinkItem.href}
            className="sidebar-link border-b-2 py-2 w-full ml-auto hover:border-black flex items-center justify-center text-[15px] text-black hover:text-primary cursor-pointer no-underline list-none transition ease-in-out duration-200"
          >
            {navLinkItem.name}
          </Link>
        )
        : (
          <div
            className="border-b-2 py-2 w-full ml-auto hover:border-black flex flex-col items-center justify-center text-[15px] text-black hover:text-primary cursor-pointer no-underline list-none transition ease-in-out duration-200"
          >
            <button
              className="flex gap-x-1 items-center"
              onClick={toggleDropdownHandler}
            >
              <span>{navLinkItem.name}</span>
              <MdKeyboardArrowDown/>
            </button>
            <div className={`${
              dropdown ? 'block' : 'hidden'
            } lg:hidden py-4 w-full`}>
              <ul
                className="bg-transparent text-center flex flex-col overflow-hidden">
                {navLinkItem.items.map((page, index) => (
                  <Link
                    key={index}
                    to={page.href}
                    className="border-b-2 py-2 w-full ml-auto hover:border-black flex flex-col items-center justify-center text-[15px] text-black hover:text-primary cursor-pointer no-underline list-none transition ease-in-out duration-200"
                  >
                    {page.title}
                  </Link>
                ))}
              </ul>
            </div>
          </div>
        )}
    </>
  );
};

export const SideBtnWrap = ({ children }) => (
  <div
    className="border-b-2 py-2 w-full ml-auto hover:border-black flex items-center justify-center text-[15px] text-black hover:text-primary cursor-pointer no-underline list-none transition ease-in-out duration-200"
  >{children}</div>
);

export const SidebarRoute = ({ children, ...rest }) => (
  <Link {...rest} className="w-full ml-auto hover:border-black flex flex-col items-center justify-center text-[15px] text-black hover:text-primary cursor-pointer no-underline list-none transition ease-in-out duration-200">
    {children}
  </Link>
);
