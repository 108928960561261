import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(Backend)
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    debug: false,
    supportedLngs: ['en', 'ru', 'hy'],
    lng: 'en',
    fallbackLng: 'en',
    saveMissing: false,
    returnEmptyString: false,
    detection: {
      caches: ['cookie'],
      order: ['path']
    },
    interpolation: {
      escapeValue: false,
      formatSeparator: '.'
    },
    backend: {
      loadPath: process.env.REACT_APP_HOST + '/api/translations/{{lng}}',
      addPath: process.env.REACT_APP_HOST + '/api/translations/add'
    }
  });

export default i18n;
