import React, { useEffect, useState } from 'react';

import ProductCardSkeleton from '../components/product/ProductCardSkeleton';
import ErrorMessage from '../components/ErrorMessage';
import MainLayout from '../components/MainLayout';
import { PageSection } from '../components/section/SectionElements';
import { useTranslation } from 'react-i18next';
import { ProductCard } from '../components/product/ProductCard';
import { toast } from 'react-hot-toast';
import { getProductsByBrand } from '../services/index/products';
import { getAllBrands } from '../services/index/brands';

// eslint-disable-next-line no-unused-vars
const groupItemsByName = (items) => {
  const groupedItems = {};

  items.forEach(item => {
    const name = item.names.en; // You can change 'en' to the language you prefer
    if(!groupedItems[name]) {
      groupedItems[name] = [];
    }
    groupedItems[name].push(item);
  });

  return groupedItems;
};

const Products = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [products, setProducts] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    let prods = {};
    const fetchBrands = async () => {
      try {
        const { data, isLoading, isError } = await getAllBrands();
        setIsLoading(isLoading);
        setIsError(isError);
        data.result.map((b) => fetchBrandProducts(b));
      } catch (error) {
        toast.error(error.message);
        console.error(error);
      }
    };
    const fetchBrandProducts = async (brand) => {
      try {
        const { data, isError } = await getProductsByBrand({ slug: brand.id });
        prods = { ...prods, [brand.name]: groupItemsByName(data) };
        setProducts(prods);
        setIsError(isError);
      } catch (error) {
        toast.error(error.message);
        console.error(error);
      }
    };
    fetchBrands();
    // Fetch studioWorks when the component mounts
  }, []);

  useEffect(() => {
    products && setIsLoading(Object.keys(products).length !== 4);
  }, [products]);

  return (
    <MainLayout>
      <PageSection className="bg-white">
        <div className="container">
          <div className="flex flex-col md:justify-start justify-center md:gap-x-5 gap-y-5 pb-10">
            {isLoading && [...Array(3)].map((item, index) => (
              <ProductCardSkeleton
                key={index}
                className="w-full md:w-[calc(50%-20px)] lg:w-[calc(33.33%-21px)]"
              />
            ))}
            {isError && <ErrorMessage message="Couldn't fetch the product data" /> }
            {!isLoading && !isError && products &&
              <>
                {products['Multi Tabak'] &&
                  <div className='flex flex-col'>
                    <h3 className="section-title text-left mb-20">{t('High Segment')}</h3>
                    <div className='grid grid-cols-1 md:grid-cols-3 gap-5'>
                      {Object.keys(products['Multi Tabak']).map((name, key) => (
                        <div key={name} className='flex flex-col gap-5'>
                          { products['Multi Tabak'][name].map((item, key) => <ProductCard
                            key={key.toString()}
                            product={item}
                            className="w-full flex flex-col justify-between min-h-[440px] lg:min-h-[480px]"
                          />)}
                        </div>
                      )) }
                    </div>
                  </div>
                }
                {products.Hatis &&
                  <div className='flex flex-col'>
                    <h3 className="section-title text-left mb-20">{t('Middle Segment')}</h3>
                    <div className='grid grid-cols-1 md:grid-cols-3 gap-5'>
                      {Object.keys(products.Hatis).map((name, key) => (
                        <div key={name} className='flex flex-col gap-5'>
                          { products.Hatis[name].map((item, key) => <ProductCard
                            key={key.toString()}
                            product={item}
                            className="w-full flex flex-col justify-between min-h-[440px] lg:min-h-[480px]"
                          />)}
                        </div>
                      )) }
                    </div>
                  </div>
                }
                {products.Kars &&
                  <div className='flex flex-col'>
                    <h3 className="section-title text-left mb-20">{t('Low Segment')}</h3>
                    <div className='grid grid-cols-1 md:grid-cols-3 gap-5'>
                      {Object.keys(products.Kars).map((name, key) => (
                        <div key={name} className='flex flex-col gap-5'>
                          {products.Kars[name].map((item, key) => <ProductCard
                            key={key.toString()}
                            product={item}
                            className="w-full flex flex-col justify-between min-h-[440px] lg:min-h-[480px]"
                          />)}
                        </div>
                      )) }
                    </div>
                  </div>
                }
                {products['Multi Class'] &&
                  <div className='flex flex-col'>
                    <h3 className="section-title text-left mb-20">{t('Capsule Segment')}</h3>
                    <div className='grid grid-cols-1 md:grid-cols-3 gap-5'>
                      {Object.keys(products['Multi Class']).map((name, key) => (
                        products['Multi Class'][name].map((item, key) => <ProductCard
                          key={key.toString()}
                          product={item}
                          className="w-full flex flex-col justify-between min-h-[440px] lg:min-h-[480px]"
                        />)
                      )) }
                    </div>
                  </div>
                }
              </>
            }
            {!isLoading && !isError && !products && <h2 className="text-center text-lg">{t('No Product Found')}</h2>}
          </div>
        </div>
      </PageSection>
    </MainLayout>);
};

export default Products;
