import React, { useEffect, useRef, useState } from 'react';
import { Link, Link as LinkRouter, NavLink, useLocation, useNavigate, useParams } from 'react-router-dom';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
export const Nav = ({ children, scrollNav }) => {
  const background = scrollNav ? 'shadow-lg' : 'shadow-0';
  return (
    <nav
      className={`${background} bg-black60 h-16 flex justify-center items-center text-base sticky top-0 z-900 lg:transition-all lg:duration-500 lg:ease-linear`}
    >
      {children}
    </nav>
  );
};

export const NavbarContainer = ({ children }) => (
  <div className="container px-5 flex justify-between h-full items-center">
    {children}
  </div>
);

export const NavLogo = ({ children, ...rest }) => (
  <LinkRouter
    {...rest}
    className="justify-self-start cursor-pointer text-2xl flex items-center ml-0.5 font-MontserratArmSemiBold no-underline h-full py-3"
  >
    {children}
  </LinkRouter>
);

export const MobileIcon = ({ children, ...rest }) => (
  <div
    {...rest}
    className="lg:hidden md:hidden block absolute top-0 right-4 transform translate-y-2/3 text-3xl text-white cursor-pointer"
  >
    {children}
  </div>
);

export const NavMenu = ({ children }) => (
  <ul className="items-start md:text-white lg:flex md:flex hidden text-sm font-Montserrat">
    {children}
  </ul>
);

export const NavItem = ({ children, className }) => <li className={`relative group cursor-pointer flex ${className}`}>{children}</li>;

export const NavLinks = ({ navLinkItem, ...rest }) => {
  const dropdownRef = useRef(null);
  const isDropDown = (navLinkItem.type && navLinkItem.type === 'dropdown');
  const isLocales = (navLinkItem.type && navLinkItem.type === 'locales');
  const [dropdown, setDropdown] = useState(false);
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { lang } = useParams();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    navigate(lang ? location.pathname.replace(lang, lng) : `/${lng}${location.pathname}`);
  };
  const toggleDropdownHandler = () => {
    setDropdown((curState) => {
      return !curState;
    });
  };

  const handleClickOutside = (event) => {
    if(dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdown(false);
    }
  };

  useEffect(() => {
    // Add the event listener when the component mounts
    document.addEventListener('click', handleClickOutside);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <>
      { !isDropDown && !isLocales && <>
        <NavLink
          to={`/${lang || 'en'}${navLinkItem.href}`}
          replace={true}
          {...rest}
          preventScrollReset={false}
          className="navLink px-4 py-2">
          {t(navLinkItem.name)}
        </NavLink>
        <span className="cursor-pointer text-primary absolute transition-all duration-500 left-[5rem] bottom-0 group-hover:left-[1rem] w-[calc(100%-2rem)] h-[1px] bg-white opacity-0 group-hover:opacity-100"></span>
      </> }
      { isDropDown && <div ref={dropdownRef} className="flex flex-col md:items-center items-end">
        <button
          className="px-4 py-2 flex gap-x-1 items-center md:flex-row flex-row-reverse"
          onClick={toggleDropdownHandler}
        >
          <span>{t(navLinkItem.name)}</span>
          <MdKeyboardArrowDown/>
        </button>
        <div className={`${
          dropdown ? 'block' : 'hidden'
        } transition-all duration-500 md:pt-4 lg:absolute lg:bottom-0 lg:right-0 lg:transform lg:translate-y-full lg:group-hover:block w-max`} >
          <ul className="bg-dark-light text-start flex flex-col md:shadow-lg md:rounded-lg overflow-hidden w-full">
            {navLinkItem.items.map((page, index) => (
              <Link
                key={index}
                to={page.href}
                className="hover:bg-dark-soft hover:text-white px-4 py-2 text-black md:text-start text-end"
              >
                {t(page.title)}
              </Link>
            ))}
          </ul>
        </div>
      </div> }
      {isLocales && <div ref={dropdownRef} className="flex flex-col items-center">
        <button
          className="px-4 py-2 flex gap-x-1 items-center"
          onClick={toggleDropdownHandler} >
          <span>{navLinkItem?.locales[i18n.language].name}</span>
          <MdKeyboardArrowDown/>
        </button>
        <div className={`${
          dropdown ? 'block' : 'hidden'
        } transition-all duration-500 pt-4 lg:absolute lg:bottom-0 lg:right-0 lg:transform lg:translate-y-full lg:group-hover:block w-max`} >
          <ul className="bg-dark-light text-start flex flex-col shadow-lg rounded-lg overflow-hidden w-full">
            {Object.keys(navLinkItem?.locales).map((locale) => (
              <NavBtn
                key={locale}
                onClick={() => changeLanguage(locale)}
                className={`hover:bg-dark-soft hover:text-white px-4 py-2 text-black text-start cursor-pointer group ${i18n.language === locale ? 'font-MontserratArmSemiBold' : 'font-normal'}`}>
                <p>{navLinkItem?.locales[locale].name}</p>
              </NavBtn>
            ))}
          </ul>
        </div>
      </div>
      }
    </>
  );
};

export const NavBtn = ({ children, className, ...rest }) => (
  <button
    {...rest}
    className={className || 'm-0 lg:flex md:flex hidden gap-x-1 items-center border-2 border-primary px-6 py-2 rounded-full text-primary font-MontserratArmSemiBold hover:bg-primary hover:text-white transition-all duration-300'}>
    {children}
  </button>
);

export const NavBtnLink = ({ children, classname, ...rest }) => (
  <LinkRouter {...rest} className={classname}>
    {children}
  </LinkRouter>
);
